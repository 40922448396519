import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  MIDDLE_WARE_BASE_URL,
  GET_REQUESTSTATUS_DETAILS,
} from "../../constants";
import axios from "../../datasource/axios-instance";

const initialState = {
  requestStatusStatus: null,
  requestStatusState: null,
  requestStatusDetailsData: null,
};

export const getRequestStatusDetails = createAsyncThunk(
  "get/getRequestStatusDetails",
  async () => {
    const response = await axios.getRequest(
      MIDDLE_WARE_BASE_URL() +
        GET_REQUESTSTATUS_DETAILS()
   
    );
    return response;
  }
);

export const getRequestStatusDetailsSlice = createSlice({
  name: "getRequestStatusDetails",
  initialState,
  reducers: {},
  extraReducers: (builder: any) =>
    builder
      .addCase(getRequestStatusDetails.pending, (state: any) => {
        state.requestStatusState = "pending";
      })
      .addCase(getRequestStatusDetails.fulfilled, (state: any, action: any) => {
        state.requestStatusState = "fulfilled";
        state.requestStatusStatus = "success";
        state.requestStatusDetailsData = action.payload.data;
      })
      .addCase(getRequestStatusDetails.rejected, (state: any) => {
        state.requestStatusState = "failed";
        state.requestStatusStatus = "fail";
      }),
});

export const getRequestStatusDetailsDetails = (state: any) =>
  state.getRequestStatusDetails || {};

export default getRequestStatusDetailsSlice.reducer;
