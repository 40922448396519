import React, { useEffect } from 'react';
import { AllRoutes } from './routes/AllRoutes';
import { BrowserRouter } from "react-router-dom";
import Spinner from "./components/spinner/spinner";
import HeaderSection from "./components/layouts/header/header"; 
import FooterSection from "./components/layouts/footer/footer";
import { sendTokenRequest } from "./pages/login/tokenService";
import './styles/App.scss';

function App() {
  const code = new URL(window.location.href).searchParams.get("code");
  const token =  localStorage.getItem("AccessToken");
  const showHAF =  localStorage.getItem("isApiCalled");

  useEffect(()=>{
    if (code && token === null && (localStorage.getItem("isApiCalled") === "false" || localStorage.getItem("isApiCalled") === null)) {
      sendTokenRequest(code);
      localStorage.setItem("isApiCalled", "true");
    }
  },[code,token])


  return (
    <>
      <div className="App">
        <BrowserRouter>
          {(token || showHAF === "true") &&
        <HeaderSection/>
}
          <div className = "middleLayout">
          <AllRoutes />
          </div>
            {(token || showHAF === "true") &&
         <FooterSection/>
          }
        </BrowserRouter>
      </div>
      <div className="divSpinner" style={{ display: "none" }}><Spinner /></div>
    </>
  );
}

export default App;
