import React from "react";
import { Route, Routes } from "react-router-dom";
import { NotFound } from "../pages/no-page/NotFound";
import { LeaveRequest } from "../pages/leaverequest/LeaveRequest";
import {
  LEAVEREQUEST,
  LOGIN,
  REQUESTSTATUS,
  LEAVEREQUESTSUMMARY,
  LOAD,
  LEAVEREQUESTORAPPROVALPAGE,
  LEAVEREQUESTORDOWNTHEAPPROVALPAGE,
  LEGACYDATA
  
} from "../constants"; //  REPORTINGPAGE,
import Login from "../pages/login/login";
import ProtectedRoute from "./protectedRoutes";
import PublicRoute from "./publicRoutes";
import RequestStatus from "../pages/RequestStatus/RequestStatus";
import LeaveApprovalOrRejectPage from "../pages/LeaveApprovalOrRejectPage/LeaveApprovalOrRejectPage";
import LeaveRequestSummaryPage from "../pages/LeaveRequestSummaryPage/leaveRequestSummaryPage"
import { InitialLoadPage } from "../components/initialloadpage/initialLoadPage";
import LeaveApprovalPathOrRejectPathPage from "../pages/LeaveApprovalPathOrRejectPathPage/LeaveApprovalPathOrRejectPathPage";
import {LegacyData} from "../pages/LegacyData/LegacyData";

export const AllRoutes = () => {
  return (
    <>
      <Routes>
        <Route element={<PublicRoute />}>
        <Route exact path={LOGIN} element={<Login />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route exact path={LOAD} element={<InitialLoadPage />} />
          <Route exact path={LEAVEREQUEST} element={<LeaveRequest />} />
          <Route exact path={REQUESTSTATUS} element={<RequestStatus />} />
          <Route exact path={LEAVEREQUESTSUMMARY} element={<LeaveRequestSummaryPage />} />
          <Route exact path={LEAVEREQUESTORAPPROVALPAGE} element={<LeaveApprovalOrRejectPage />} />
          <Route exact path={LEAVEREQUESTORDOWNTHEAPPROVALPAGE} element={<LeaveApprovalPathOrRejectPathPage />} />
          <Route exact path={LEGACYDATA} element={<LegacyData />} />
          <Route exact path="*" element={<NotFound />} />
        </Route>
          {/* <Route exact path={MANAGERPAGE} element={<ManagerPage />} />
          <Route
            exact
            path={SENIORMANAGERAPPROVAL}
            element={<SeniorManagerApproval />}
          />
          <Route exact path={DIRECTORAPPROVAL} element={<DirectorApproval />} />
           <Route exact path={REPORTINGPAGE} element={<ReportingPage />} /> 
           <Route exact path={HCADMINPAGE} element={<HcAdminPage />} /> 
           <Route element={<RestrictionRoute />}>
        <Route exact path={ACCESSDENIED} element={<AccessDenied />} />
        </Route> */}
      </Routes>
    </>
  );
};
