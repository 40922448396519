import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  MIDDLE_WARE_BASE_URL,
  CANCEL_LEAVE,
} from "../../constants";
import axios from "../../datasource/axios-instance";

const initialState = {
  deleteRequestStatusStatus: null,
  deleteRequestStatusState: null,

};

export const deleteRequestStatusDetails = createAsyncThunk(
  "delete/deleteRequestStatusDetails",
  async (data:any) => {
    const response = await axios.deleteRequest(
      MIDDLE_WARE_BASE_URL() +
      CANCEL_LEAVE()+'/'+data.uid+'?startDate='+data.startDate+'&endDate='+data.endDate
    );
    return response;
  }
);

export const deleteRequestStatusDetailsSlice = createSlice({
  name: "deleteRequestStatusDetails",
  initialState,
  reducers: {},
  extraReducers: (builder: any) =>
    builder
      .addCase(deleteRequestStatusDetails.pending, (state: any) => {
        state.deleteRequestStatusState = "pending";
      })
      .addCase(deleteRequestStatusDetails.fulfilled, (state: any, action: any) => {
        state.deleteRequestStatusState = "fulfilled";
        state.deleteRequestStatusStatus = "success";
        
      })
      .addCase(deleteRequestStatusDetails.rejected, (state: any) => {
        state.deleteRequestStatusState = "failed";
        state.deleteRequestStatusStatus = "fail";
      }),
});

export const deleteRequestStatusDetailsDetails = (state: any) =>
  state.deleteRequestStatusDetails || {};

export default deleteRequestStatusDetailsSlice.reducer;
