import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import LeaveRequestTypeReducer from "../../pages/leaverequest/LeaveRequestTypeSlice";
import getEmpDetailsReducer from "../../pages/leaverequest/GetEmployeeDetailsSlice";
import createLeaveRequestReducer from "../../pages/leaverequest/CreateLeaveRequestSlice";
import getRequestStatusDetailsDetailsReducer from "../../pages/RequestStatus/RequestStatusSlice";
import getLeaveRequestSummaryReducer from "../../pages/LeaveRequestSummaryPage/leaveRequestSummarySlice";
/* import getManagerLeaveDetailsReducer from "../../pages/managerapproval/managerApprovalSlice";
import managerLeaveUpdateDetailsReducer from "../../pages/managerapproval/mangerApprovalUpdateSlice";
import getHcAdminDetailsReducer from "../../pages/HcAdminPage/HcAdminPageSlice";

import getReportingPageDetailsReducer from "../../pages/ReportingPage/ReportingPageSlice";
import getSeniorManagerLeaveDetailsReducer from"../../pages/seniormanagerapproval/seniorManagerApprovalSlice";
import SeniorManagerLeaveUpdateDetailsReducer from "../../pages/seniormanagerapproval/seniorManagerApprovalUpdateSlice";
import getDirectorLeaveDetailsReducer from "../../pages/directorapprovalpage/directorApprovalSlice"
import getSrManagersListReducer from "../../pages/directorapprovalpage/delegateSeniorManagerSlice";
import DelegateApproverDetailsReducer from "../../pages/directorapprovalpage/delegateSeniorManagerApprovalSlice"; */
import deleteRequestStatusDetailsReducer from "../../pages/RequestStatus/RequestDeleteSlice";
import leaveHolidayTypesReducer from "../../pages/leaverequest/LeaveHolidaysCheckSlice";
import getLeaveApprovalDetailsReducer from "../../pages/LeaveApprovalOrRejectPage/LeaveApprovalOrRejectPageSlice";
import LeaveUpdateDetailsReducer from "../../pages/LeaveApprovalOrRejectPage/UpdateLeaveApprovalSlice";
import getLeaveApprovalDetailsByFilterReducer from "../../pages/LeaveApprovalOrRejectPage/LeaveApprovalOrRejectByFilterSlice"
import getIntialAdminDetailsReducer from "../../components/initialloadpage/intialAdminDetailsSlice";
import legacyDataTypeDataReducer from "../../pages/LegacyData/LegacyDataSlice";
export const store = configureStore({
  reducer: {
    leaveReqTypes: LeaveRequestTypeReducer,
    getEmpDetails: getEmpDetailsReducer,
    createLeaveRequest: createLeaveRequestReducer,
    getRequestStatusDetails: getRequestStatusDetailsDetailsReducer,
    getLeaveRequestSummary:getLeaveRequestSummaryReducer,
   /*  getManagerLeaveDetails:getManagerLeaveDetailsReducer,
    ManagerLeaveUpdateDetails:managerLeaveUpdateDetailsReducer,
    getHcAdminDetails :getHcAdminDetailsReducer,
    getReportingPageDetails : getReportingPageDetailsReducer,
    getSeniorManagerLeaveDetails : getSeniorManagerLeaveDetailsReducer,
    SeniorManagerLeaveUpdateDetails : SeniorManagerLeaveUpdateDetailsReducer,
    getDirectorLeaveDetails: getDirectorLeaveDetailsReducer,
    getSrManagersList : getSrManagersListReducer,
    DelegateApproverDetails:DelegateApproverDetailsReducer, */
    deleteRequestStatusDetails:deleteRequestStatusDetailsReducer,
    leaveHolidayTypes:leaveHolidayTypesReducer,
    getLeaveApprovalDetails: getLeaveApprovalDetailsReducer,
    LeaveUpdateDetails:LeaveUpdateDetailsReducer,
    getLeaveApprovalDetailsByFilter:getLeaveApprovalDetailsByFilterReducer,
    getIntialAdminDetails: getIntialAdminDetailsReducer,
    legacyDataTypeData:legacyDataTypeDataReducer
  },
  middleware: [thunk],
});
