import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { MIDDLE_WARE_BASE_URL, GET_EMPLOYEE_DETAILS } from '../../constants';
import axios from '../../datasource/axios-instance'; 

const initialState = {
    empDetailsStatus : null,
    empDetailsState : null,
    empDetailsData : null
};

export const getEmpDetails = createAsyncThunk(
    'get/getEmpDetails', async() =>  {
       const response = await axios.getRequest(MIDDLE_WARE_BASE_URL() + GET_EMPLOYEE_DETAILS());
       return response;
});

export const getEmpDetailsSlice = createSlice({
    name : 'getEmpDetails',
    initialState,
    reducers : {},
    extraReducers : (builder : any) => 
       builder
       .addCase(getEmpDetails.pending, (state : any) =>
       {
            state.empDetailsState = 'pending';
       })
       .addCase(getEmpDetails.fulfilled, (state : any,action : any) =>
       {
           state.empDetailsState = 'fulfilled';
           state.empDetailsStatus = 'success';
           state.empDetailsData = action.payload.data;
        })
       .addCase(getEmpDetails.rejected, (state : any) =>
       {
            state.empDetailsState = 'failed';
            state.empDetailsStatus = 'fail';
       })
});

export const getEmpDetailsDetails = ((state : any) => state.getEmpDetails || {});
 
export default getEmpDetailsSlice.reducer;