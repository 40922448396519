import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  MIDDLE_WARE_BASE_URL,
  GET_EMPLOYEE_LEAVE_APPROVALS,
} from "../../constants";
import axios from "../../datasource/axios-instance";

const initialState = {
  LeaveApprovalDataStatus: null,
  LeaveApprovalDataState: null,
  LeaveApprovalData: null,
};

export const LeaveApprovalDetails = createAsyncThunk(
  "get/getLeaveApprovalDetails",
  async (data: any) => {
    const response = await axios.getRequest(
      MIDDLE_WARE_BASE_URL() +
      GET_EMPLOYEE_LEAVE_APPROVALS() +
        "?oracleId=" +
        data.oracleId +"&requestedby=" + data.requestedby+"&approvalsListingType="+data.approvalsListingTypeVal
    );
    return response;
  }
);

export const getLeaveApprovalDetailsSlice = createSlice({
  name: "getLeaveApprovalDetails",
  initialState,
  reducers: {},
  extraReducers: (builder: any) =>
    builder
      .addCase(LeaveApprovalDetails.pending, (state: any) => {
        state.LeaveApprovalDataState = "pending";
      })
      .addCase(LeaveApprovalDetails.fulfilled, (state: any, action: any) => {
        state.LeaveApprovalDataState = "fulfilled";
        state.LeaveApprovalDataStatus = "success";
        state.LeaveApprovalData = action.payload.data;
      })
      .addCase(LeaveApprovalDetails.rejected, (state: any) => {
        state.LeaveApprovalDataState = "failed";
        state.LeaveApprovalDataStatus = "fail";
      }),
});

export const selectLeaveApprovalDetails = (state: any) =>
  state.getLeaveApprovalDetails || {};

export default getLeaveApprovalDetailsSlice.reducer;
