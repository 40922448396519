import axios from "axios";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { useState, useEffect } from "react";
import { useSelector, useDispatch} from "react-redux";
import { LEGACY_DATA_PDF, MIDDLE_WARE_BASE_URL } from "../../constants";
import {
  legacyDataType,
  selectlegacyDataTypeDetails,
} from "./LegacyDataSlice"
export function LegacyData() {
  const dispatch = useDispatch();
  const [data, setData] = useState<any>([]);
  const [search, setSearch] = useState("");
  const token =  localStorage.getItem("AccessToken");
  const { legacyDataTypeData, legacyDataTypeState } = useSelector(
    selectlegacyDataTypeDetails
  );
 
  useEffect(() => {
    if (legacyDataTypeState === "fulfilled") {
      setData(legacyDataTypeData);
    }
  }, [legacyDataTypeData, legacyDataTypeState]);

  function handleSubmit()
  {
    var data = {
      searchparams: search
    
    };
    dispatch<any>(legacyDataType(data));
  }
  const documentBodyTemplate=(rowData:any)=>{

    if(rowData.VacationRequestId ){
    return  <Button type="button" icon="pi pi-file-pdf" rounded 
    onClick={()=> documentBodyTemplateDownload(rowData)}></Button>;
    }else{
      return "-";
    }
  };
  const documentBodyTemplateDownload = (rowData: any) => {

    axios({
        method: 'get',
        url: MIDDLE_WARE_BASE_URL() + LEGACY_DATA_PDF()+'?requestid='+rowData.VacationRequestId,
        headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
        },
        responseType: 'blob'
    }).then(function (response) {
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(response.data);
        a.download = rowData.SAPID+'_'+rowData.EmployeeName+`.pdf`;
        document.body.appendChild(a);
        a.click();
        a.remove();
    });

   
          }
          function clear()
          {
      setSearch("");
      setData([]);
   
          }
    return (
        
        <div className="container-fluid">
        <div className="row">
        <div className="col-xs-7 col-sm-6 col-lg-8">
            <span className="p-input-icon-left">

    <InputText placeholder="Search" 
     value={search}
     onChange={(e: any) => setSearch(e.target.value)}
     className="inputBox m-2" />
</span>
<Button label="Submit" className="submitButton m-2"    onClick={handleSubmit} />
<Button label="Clear" className="clearButton m-2"    onClick={clear} />
<div className="col-xs-5 col-sm-6 col-lg-4">
</div>
</div>
</div>
<DataTable
          value={data}
          showGridlines
          tableStyle={{ minWidth: "50rem" }}
            rows={10}
          paginator
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={[10, 25, 50,100]}
          emptyMessage="No employees found."
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          style={{ fontSize: "0.75rem" }}
   
        >
        
          <Column
            field="SAPID"
       
            header= "SAP ID"
            style={{ minWidth: "1rem", maxWidth: "10rem" }}
            className="pe-1"
          ></Column>
          <Column
            field="EmployeeName"
       
            header= "Employee Name" 
            style={{ minWidth: "1rem", maxWidth: "10rem" }}
            className="pe-1"
          ></Column>
          <Column
            field="StartDate"
            header= "Start Date"
            style={{ minWidth: "1rem", maxWidth: "10rem" }}
            className="pe-1"
          ></Column>
          <Column
            field="EndDate"
            header= "End Date" 
            style={{ minWidth: "1rem", maxWidth: "10rem" }}
            className="pe-1"
          ></Column>
         
       
          <Column
            field="LeaveRequestType"
      
            header= "Leave type" 
            style={{ minWidth: "1rem", maxWidth: "10rem" }}
            className="pe-1"
          ></Column>
         
 
     
          <Column
            field="ApproverName"
           
            header= "Actioned By" 
            style={{ minWidth: "1rem", maxWidth: "10rem" }}
            className="pe-1"
          ></Column>
      <Column
            field="Document"
            body={documentBodyTemplate}
            header= "Document"
          ></Column>
        </DataTable>
            </div>
         
            
    );
}
