import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  MIDDLE_WARE_BASE_URL,
  GET_MANAGER_DETAILS,
} from "../../constants";
import axios from "../../datasource/axios-instance";

const initialState = {
 LeaveRequestSummaryStatus: null,
 LeaveRequestSummaryState: null,
 LeaveRequestSummaryDetailsData: null,
};

export const getLeaveRequestSummary = createAsyncThunk(
  "get/getLeaveRequestSummary",
  async (data: any) => {
    const response = await axios.getRequest(
      MIDDLE_WARE_BASE_URL() +
      GET_MANAGER_DETAILS() +
        "?oracleId=" +
        data.oracleId+"&requestedby=" + data.requestedBy
    );
    return response;
  }
);

export const getLeaveRequestSummarySlice = createSlice({
  name: "getLeaveRequestSummary",
  initialState,
  reducers: {},
  extraReducers: (builder: any) =>
    builder
      .addCase(getLeaveRequestSummary.pending, (state: any) => {
        state.LeaveRequestSummaryState = "pending";
      })
      .addCase(getLeaveRequestSummary.fulfilled, (state: any, action: any) => {
        state.LeaveRequestSummaryState = "fulfilled";
        state.LeaveRequestSummaryStatus = "success";
        state.LeaveRequestSummaryDetailsData = action.payload.data;
      })
      .addCase(getLeaveRequestSummary.rejected, (state: any) => {
        state.LeaveRequestSummaryState = "failed";
        state.LeaveRequestSummaryStatus = "fail";
      }),
});

export const getLeaveRequestSummaryDetails = (state: any) =>
  state.getLeaveRequestSummary || {};

export default getLeaveRequestSummarySlice.reducer;
