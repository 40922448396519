import jwt_decode from "jwt-decode";
import axios from "axios";
import { ClearStorage } from "./storage";

export const showLoader = () => {
    const node : any = document.querySelector('.divSpinner');
    node.removeAttribute("style");
};

export const hideLoader = () => {
   const node : any = document.querySelector('.divSpinner');
   node.style.display = "none";
    };

    const getTokenRequestHeaders = () => {
        return {
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": localStorage.getItem("AccessToken")
            }
        };
    };

    export const sendRefreshTokenRequest = async (grantType : any , refreshToken : any) => {
        const body = [];
        body.push(`grant_type=${ grantType }`);
        body.push(`refresh_token=${ refreshToken }`);

        return await axios.post(`https://identity.ttec.com/as/token.oauth2`,body.join("&"), getTokenRequestHeaders())
            .then((response : any) => {
                if (response?.status !== 200) {
                    return Promise.reject(new Error("Invalid status code received in the token response: "
                        + response?.status));
                }
                const decodedToken : any = jwt_decode(response?.data?.access_token);

                localStorage.setItem("AccessToken", "Bearer "+response?.data?.access_token + '');
                localStorage.setItem("tokenExpireTime", decodedToken.exp ? decodedToken.exp.toString() : '');
                localStorage.removeItem("isRefreshTknCalled");
                window.location.reload();
                return Promise.resolve();
    
            }).catch((error) => {
                 alert("Your session is expired.");
                 ClearStorage();
                 let logoutRequest = `https://identity.ttec.com/idp/startSLO.ping`;
                 window.location.href = logoutRequest; 
                 return Promise.reject(new Error('RefreshToken Something went wrong'));
            });
    };
    

    export const refreshToken = async(): Promise<any> => {
        const token : any = localStorage.getItem("AccessToken");
        if(token)
        {
        const decodedToken : any =  jwt_decode(token);
        if(decodedToken)
        {
        let date = new Date("1970-01-01 00:00:00:000");
        date.setSeconds(date.getSeconds() + decodedToken.exp);
        const dateString = date.toISOString();
        const userOffset = new Date().getTimezoneOffset() * 60 * 1000;
        const localDate = new Date(dateString);
        const utcDate = new Date(localDate.getTime() - userOffset);
        const currentDate = new Date();
        const currentDateTime = new Date(currentDate.setMinutes(currentDate.getMinutes() +5));
        const tokenExpDateTime = new Date(utcDate);
        var isTokenExpired = (currentDateTime >= tokenExpDateTime);
       if(isTokenExpired && (localStorage.getItem("isRefreshTknCalled") === "false" || localStorage.getItem("isRefreshTknCalled") === null)){
            
        try{
            localStorage.setItem("isRefreshTknCalled", "true");
            sendRefreshTokenRequest('refresh_token', localStorage.getItem("RefreshToken"));
        } catch (error) {
              return Promise.reject(
                  new Error('RefreshToken Something went wrong')
              );
          }
        }
         }
      }
      }
      