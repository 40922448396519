import axios, { AxiosInstance, AxiosResponse } from "axios";
import { showLoader, hideLoader, refreshToken } from "./msal";

const defaultHeaders = {
  "Content-Type": "application/json",
};
const getAccessToken = () => {
  const SSOToken = localStorage.getItem("AccessToken");
  const nonSSOToken = localStorage.getItem("AccessToken-NonSSO");
  const token = SSOToken ? SSOToken : nonSSOToken;
  return token;
};

class httpConfig {
  Axios: AxiosInstance;
  constructor(baseURL: string) {
    this.Axios = axios.create({
      baseURL,
    });
    this.setUpInterceptors();
  }

  setUpInterceptors() {
    this.Axios.interceptors.response.use(
      (response: AxiosResponse) => response,
      (error: any) => {
        const errorData = error?.response?.data || error;
        const errorObj = {
          ...errorData,
          message: errorData?.message?.includes("Already Exists")
            ? errorData?.message
              : "Operation failed. Please try again after some time.",
        };

        if (errorData.correlationId) {
          errorObj.message = `${errorObj.message}\nCode: ${errorData.correlationId}`;
        }

        const errCode = error?.response?.status;
        if (
          errCode === 404 ||
          errCode === 500 ||
          errCode === 400 ||
          errCode === 504
        ) {
        }
        if (typeof error?.response === "undefined") {
          const corsErr = {
            type: "CORB",
            message: "Operation failed. Please try again after some time",
          };
          errorData.push(corsErr);
        }
        throw error;
      }
    );

    this.Axios.interceptors.request.use(
      async (request: any) => {
        await refreshToken();
        request.headers.Authorization = getAccessToken();
        return request;
      },
      (error) => {
        throw error;
      }
    );
  }

  getRequest(url: string, headers: any = {}) {
    showLoader();
    return this.Axios.get(url, {
      headers: { ...defaultHeaders, ...headers },
    }).then(function (response) {
      hideLoader();
      return (response?.data ? response.data : response);
    }).catch(function (error) { hideLoader(); throw error });
  }

  postRequest(url: string, data: any, headers: any = {}) {
    showLoader();
    return this.Axios.post(url, data, {
      headers: { ...defaultHeaders, ...headers },
    }).then(function (response) {
      hideLoader();
      return (response?.data ? response.data : response);
    }).catch(function (error) { hideLoader(); throw error });
  }

  postStringRequest(url: string, data: any, headers: any = {}) {
    showLoader();
    let req = JSON.stringify(data).toString();
    const replaceStr = /"/gi;
    req = req.replace(replaceStr, '\\"');
    return this.Axios.post(url, '"' + req + '"', {
      headers: { ...defaultHeaders, ...headers },
    }).then(function (response) {
      hideLoader();
      return (response?.data ? response.data : response);
    }).catch(function (error) { hideLoader(); throw error });
  }

  deleteRequest  (url: string, headers: any = {}) {
  showLoader();
   return this.Axios.delete(url, {
      headers: { ...defaultHeaders, ...headers },
    }).then(function(response){
      hideLoader();
    return (response?.data ? response.data : response);
    }).catch(function (error) { hideLoader(); throw error });
  }

  deleteRequestWithPayload = (url: string, data?: any, headers: any = {}) =>
    this.Axios.delete(url, {
      data,
      headers: { ...defaultHeaders, ...headers },
    }).then((response) => (response?.data ? response.data : response));

  putRequest = (url: string, data: any = {}, headers: any = {}) =>
    this.Axios.put(url, data, {
      headers: { ...defaultHeaders, ...headers },
    }).then((response) => (response?.data ? response.data : response));

  patchRequest(url: string, data: any, headers: any = {}) {
    showLoader();
    return this.Axios.patch(url, data, {
      headers: { ...defaultHeaders, ...headers },
    }).then(function (response) {
      hideLoader();
      return (response?.data ? response.data : response);
    }).catch(function (error) { hideLoader(); throw error });
  }
}

export default httpConfig;
