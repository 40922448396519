import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { MIDDLE_WARE_BASE_URL, LEAVE_REQUEST_TYPES } from '../../constants';
import axios from '../../datasource/axios-instance'; 

const initialState = {
    leaveReqTypeStatus : null,
    leaveReqTypeState : null,
    leaveReqTypeData : null
};

export const leaveReqTypes = createAsyncThunk(
    'get/leaveReqTypes', async() =>  {
       const response = await axios.getRequest(MIDDLE_WARE_BASE_URL() + LEAVE_REQUEST_TYPES());
       return response;
});

export const leaveReqTypesSlice = createSlice({
    name : 'leaveReqTypes',
    initialState,
    reducers : {},
    extraReducers : (builder : any) => 
       builder
       .addCase(leaveReqTypes.pending, (state : any) =>
       {
            state.leaveReqTypeState = 'pending';
       })
       .addCase(leaveReqTypes.fulfilled, (state : any,action : any) =>
       {
           state.leaveReqTypeState = 'fulfilled';
           state.leaveReqTypeStatus = 'success';
           state.leaveReqTypeData = action.payload.data;
        })
       .addCase(leaveReqTypes.rejected, (state : any) =>
       {
            state.leaveReqTypeState = 'failed';
            state.leaveReqTypeStatus = 'fail';
       })
});

export const selectleaveReqTypeDetails = ((state : any) => state.leaveReqTypes || {});
 
export default leaveReqTypesSlice.reducer;