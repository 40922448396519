import jwt_decode from "jwt-decode";

export function TokenStorage(token : any) {
    const decodedToken : any = jwt_decode(token.access_token);
    var gcaLevel = decodedToken.gcaLevel;
    // for QA Testing
    var oracleId = decodedToken.oracleID;
    localStorage.setItem("AccessToken", "Bearer "+token.access_token + '');
    localStorage.setItem("RefreshToken", token.refresh_token);
    localStorage.setItem("country", decodedToken.Country ); 
    localStorage.setItem("oracleId", decodedToken.oracleID);
    localStorage.setItem("tokenExpireTime", decodedToken.exp ? decodedToken.exp.toString() : '');
    localStorage.removeItem("isApiCalled");

    if(oracleId === "7504476" )
    {
      localStorage.setItem("gca", "S");
    }
    else if(oracleId === "7511167")
    {
      localStorage.setItem("gca", "M");
    }
    else if(oracleId === "7508457")
    {
      localStorage.setItem("gca", "A");
    }
    else{
    if(gcaLevel === "AG" || gcaLevel === "P1" || gcaLevel === "P2" || gcaLevel === "S2" || gcaLevel === "S3")
    localStorage.setItem("gca", "A");
    else if(gcaLevel === "M1" || gcaLevel === "M2" )
    localStorage.setItem("gca", "M");
    else if(gcaLevel === "M3" || gcaLevel === "P4"|| gcaLevel==="M4" )
    localStorage.setItem("gca", "S");
    else if(gcaLevel === "M6")
    localStorage.setItem("gca", "D");
    // else if(gcaLevel === "P3") 
    //  localStorage.setItem("gca", "HC");
    else
    localStorage.setItem("gca", "O"); 
    }
    
    window.location.reload();
    return decodedToken.Country;
}

export const ClearStorage = () => {
    localStorage.removeItem("AccessToken");
    localStorage.removeItem("RefreshToken");
    localStorage.removeItem("gca");
    localStorage.removeItem("oracleId");
    localStorage.removeItem("tokenExpireTime");
    localStorage.removeItem("country");
    localStorage.removeItem("isApiCalled");
    localStorage.removeItem("isRefreshTknCalled");
    localStorage.removeItem("language");
}




