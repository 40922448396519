import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useEffect, useState } from "react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { InputText } from "primereact/inputtext";
import "./leaveRequestSummaryPage.scss"
import { Panel } from 'primereact/panel';
import { Button } from "primereact/button";
import {getLeaveRequestSummaryDetails,getLeaveRequestSummary} from "./leaveRequestSummarySlice";
import {
  LeaveApprovalDetailsByFilter,
  selectLeaveApprovalDetailsByFilter,
} from "../LeaveApprovalOrRejectPage/LeaveApprovalOrRejectByFilterSlice";
import { Tag } from "primereact/tag";
import { useDispatch, useSelector } from "react-redux";
import {DOCUMENT_PDF, LOAD, MIDDLE_WARE_BASE_URL} from "../../constants";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { Toolbar } from "primereact/toolbar";
import { Calendar } from "primereact/calendar";
import moment from "moment";

const LeaveRequestSummary = () => {
  const dispatch = useDispatch();
  const [leaveData, setData] = useState<any>([]);
  const isAuth = localStorage.getItem("AccessToken");
  const navigate = useNavigate();
  const gcaLevel = localStorage.getItem("gca");
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [selectedProducts, setSelectedProducts] = useState<any>(null);
  const [lang, setLang] = useState("EN");
  const token =  localStorage.getItem("AccessToken");
  const [dates, setDates] = useState("");
  const [selectedDecision, setSelectedDecision] = useState("Pending");
  let [leavesData] = useState<any>([]);
  let [selectedRecords] = useState<any>(null);

  const { LeaveApprovalDataByFilter, LeaveApprovalDataStateByFilter } =
    useSelector(selectLeaveApprovalDetailsByFilter);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    EmployeeNo: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    EmployeeName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    StartDate: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    EndDate: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
 
    LeaveType: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
 
    Approvedby: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
  
    Status: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    activity: { value: null, matchMode: FilterMatchMode.BETWEEN },
  });

  const { LeaveRequestSummaryDetailsData, LeaveRequestSummaryState } = useSelector(
    getLeaveRequestSummaryDetails
  );

  const clearDecision = () => {
    setSelectedDecision("Pending");
    setDates("");
    const gca = localStorage.getItem("gca");
    var data = {
      oracleId : localStorage.getItem("oracleId"),
      requestedBy : (gca === "HC"||gca==="PR") ? "HCLRS" : "LRS"
    }
    dispatch<any>(getLeaveRequestSummary(data));
  };
  const leftToolbarTemplate = () => {
/*     const gcaVal = localStorage.getItem("gca");
    if (gcaVal === "HC") {
      return (
        <div className="row">
          <div className="col-3">
            <label className="p-mr-1">
              {lang === "EN" ? "Date Range" : "Период"}{" "}
            </label>
            <Calendar
              value={dates}
              onChange={(e: any) => setDates(e.value)}
              dateFormat="dd/mm/yy"
              selectionMode="range"
              readOnlyInput
            />
          </div>
          <div className="col-3">
            <label className="p-mr-1">
              {lang === "EN" ? "Decision" : "Решение"}
            </label>
            <Dropdown
              value={selectedDecision}
              onChange={(e) => setSelectedDecision(e.value)}
              options={decisions}
              placeholder="Select Decision Filter"
              className="w-full md:w-14rem"
            />
          </div>
          <div className="col-3 pt-4">
            <Button
              type="button"
              icon="pi pi-filter-icon "
              label={lang === "EN" ? "Filter" : "Филтър"}
              // outlined
              disabled={!dates}
              onClick={dateFilterFunction}
              className="mt-3 mt-md-0 ms-md-4"
            />
          </div>
          <div className="col-3 pt-4">
            <Button
              type="button"
              label={lang === "EN" ? "Clear" : "Изчистете"}
              outlined
              className="mt-3 mt-md-0 ms-md-4"
              onClick={clearDecision}
            />
          </div>
        </div>
      );
    } else { */
      return (
        <div className="row">
          <div className="col-4">
            <label className="p-mr-1">
              {lang === "EN" ? "Date Range" : "Период"}
            </label>
            <Calendar
              value={dates}
              onChange={(e: any) => setDates(e.value)}
              dateFormat="dd/mm/yy"
              selectionMode="range"
              readOnlyInput
            />
          </div>

          <div className="col-3 pt-4">
            <Button
              type="button"
              icon="pi pi-filter-icon "
              label={lang === "EN" ? "Filter" : "Филтър"}
              // outlined
              disabled={!dates}
              onClick={dateFilterFunction}
              className="mt-3 mt-md-0 w-100"
            />
          </div>
          <div className="col-3 pt-4">
            <Button
              type="button"
              label={lang === "EN" ? "Clear" : "Изчистете"}
              outlined
              onClick={clearDecision}
              className="mt-3 mt-md-0 ms-md-2 w-100"
            />
          </div>
        </div>
      );
    // }
  };
  useEffect(() => {
    if (LeaveApprovalDataStateByFilter === "fulfilled") {
      setData(LeaveApprovalDataByFilter);
    }
  }, [LeaveApprovalDataByFilter, LeaveApprovalDataStateByFilter]);
  const dateFilterFunction = () => {
    let stDate = moment(dates[0]);
    let edDate = moment(dates[1]);
    let listingType = "";
    const gcaValue = localStorage.getItem("gca");
    if (gcaValue === "HC"||gcaValue==="PR") {
      listingType = "InDirect";
    } else {
      listingType = "Direct";
    }
    var data = {
      oracleId: localStorage.getItem("oracleId"),
      requestedby: (gcaValue === "HC"||gcaValue==="PR") ? "HCLRS" : "LRS",
      approvalsListingTypeVal: listingType,
      startDate: stDate.format("YYYY-MM-DD"),
      endDate: edDate.format("YYYY-MM-DD"),
      filter: selectedDecision,
    };
    dispatch<any>(LeaveApprovalDetailsByFilter(data));
  };
  const getSeverity = (status: any) => {
    switch (status) {
      case "Declined":
        return "danger";

      case "Approved":
        return "success";

      case "Pending":
        return "info";

      case "renewal":
        return null;
   
    }
  };
  
  useEffect(() => 
  {
    if(localStorage.getItem("language") === "BG")
    {
        setLang("BG");
    }
    else{
        setLang("EN");
    }
  },[]);

 useEffect(() => {
  if(isAuth && (gcaLevel === "A" || gcaLevel === "D")){
      navigate(LOAD);
  }
}, [isAuth,gcaLevel,navigate]);

  useEffect(() => {
    const gca = localStorage.getItem("gca");
    var data = {
      oracleId : localStorage.getItem("oracleId"),
      requestedBy : (gca === "HC" || gca==="PR" )? "HCLRS" : "LRS"
    }
    dispatch<any>(getLeaveRequestSummary(data));
  }, [dispatch]);
  useEffect(() => {
    if (LeaveRequestSummaryState==="fulfilled"){
      setData(LeaveRequestSummaryDetailsData);
    
    }
   
  }, [LeaveRequestSummaryDetailsData,LeaveRequestSummaryState]);
  const onGlobalFilterChange = (event: any) => {
    const value = event.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const clearFilter = () => {
    initFilters();
  };

  const exportExcel = () => {
    if(lang === "EN")
    {
  if( selectedProducts !== null){
  selectedRecords = selectedProducts.map((u : any) => ({"Employee Name": u.EmployeeName, "Employee Number": u.EmployeeNo, "No. of Days Available" : u.DaysAvailable, "Start date" : u.StartDate, "End date" : u.EndDate, "No of Day/s Requested" : u.DaysCount, "LeaveType" : u.LeaveType, "Manager’s Name / Supervisor’s Name" : u.ManagerName + " (" + u.ManagerNumber + ")", "Actioned By" : u.Approvedby, "Comment" : u.Comment, "Decision" : u.Status, "Decline Reason" : u.DeclineReason, "Pending Action" : u.NextApprover}));
   } 
   if(leaveData !== null){
   leavesData =  leaveData.map((u : any) => ({"Employee Name": u.EmployeeName, "Employee Number": u.EmployeeNo, "No. of Days Available" : u.DaysAvailable, "Start date" : u.StartDate, "End date" : u.EndDate, "No of Day/s Requested" : u.DaysCount, "LeaveType" : u.LeaveType, "Manager’s Name / Supervisor’s Name" : u.ManagerName + " (" + u.ManagerNumber + ")", "Actioned By" : u.Approvedby, "Comment" : u.Comment, "Decision" : u.Status, "Decline Reason" : u.DeclineReason, "Pending Action" : u.NextApprover}));
   }
  }
  else{
    if( selectedProducts !== null){
      selectedRecords = selectedProducts.map((u : any) => ({"Име на служител": u.EmployeeName, "Служебен номер": u.EmployeeNo, "Брой налични дни или налични кредити" : u.DaysAvailable, "Начална дата на отпуск" : u.StartDate, "Крайна дата на отпуск" : u.EndDate, "Брой на заявените дни" : u.DaysCount, "Тип отпуск" : u.LeaveType, "Име на мениджър/супервайзър" : u.ManagerName + " (" + u.ManagerNumber + ")", "Отразена от" : u.Approvedby, "Коментари" : u.Comment, "Бутон за решение Одобрено / Отказано" : u.Status, "Причина за отказ" : u.DeclineReason, "В очакване на действие от" : u.NextApprover}));
       } 
       if(leaveData !== null){
       leavesData =  leaveData.map((u : any) => ({"Име на служител": u.EmployeeName, "Служебен номер": u.EmployeeNo, "Брой налични дни или налични кредити" : u.DaysAvailable, "Начална дата на отпуск" : u.StartDate, "Крайна дата на отпуск" : u.EndDate, "Брой на заявените дни" : u.DaysCount, "Тип отпуск" : u.LeaveType, "Име на мениджър/супервайзър" : u.ManagerName + " (" + u.ManagerNumber + ")" , "Отразена от" : u.Approvedby, "Коментари" : u.Comment, "Бутон за решение Одобрено / Отказано" : u.Status, "Причина за отказ" : u.DeclineReason, "В очакване на действие от" : u.NextApprover}));
       }
  }

    import('xlsx').then((xlsx) => {
    
        const worksheet = xlsx.utils.json_to_sheet(selectedRecords !== null && selectedRecords.length > 0 ? selectedRecords : leavesData);
        const workbook = { Sheets: { leaveData: worksheet }, SheetNames: ['leaveData'] };
        const excelBuffer = xlsx.write(workbook, {
            bookType: 'xlsx',
            type: 'array'
        });

        saveAsExcelFile(excelBuffer, 'LeaveRequests');
    });
};

const saveAsExcelFile = (buffer:any, fileName:any) => {
    import('file-saver').then((module) => {
        if (module && module.default) {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });

            module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        }
    });
};
  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      EmployeeNo: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },

      EmployeeName: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      StartDate: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      EndDate: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
    
      LeaveType: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
   
      Approvedby: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
   
      Status: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      activity: { value: null, matchMode: FilterMatchMode.BETWEEN },
    });
    setGlobalFilterValue("");
  };
  const renderHeader = () => {
   
    return (
      



      <div className="d-inline-block justify-content-between headerButtons w-100">
      <div className="d-inline-block">
  <Button
          type="button"
          icon="pi pi-filter-slash"
          label={lang === "EN" ? "Clear" : "Изчистете"}
          outlined
          style={{ marginRight: "20px" }}
          onClick={clearFilter}
        />
         <Button   className='mt-3 mt-md-0 ms-md-4' type="button" label={ lang === "EN" ? "Export Report" : "Извеждане на отчет"} onClick={exportExcel}/>
         </div>
         <div className="d-inline-block float-start float-md-end">
       
        <span className="p-input-icon-right mt-4 mt-md-0 d-inline-block ">
          <i className="pi pi-search" />
          <InputText
            type="search"
            value={globalFilterValue}
            onChange={(e) => onGlobalFilterChange(e)}
            placeholder={lang === "EN" ? "Global Search" : "Глобално търсене"}
          />
        </span>
        </div>
        </div>
    
   
    );
  };
  const header = renderHeader();

  const statusBodyTemplate = (rowData: any) => {
    let status = "";
    if(rowData.HcAdminStatus && rowData.Status && (rowData.HcAdminStatus ==='Declined'||rowData.Status ==='Declined')){
      status="Declined";
    }else if(rowData.HcAdminStatus && rowData.HcAdminStatus==='Accepted' ){
      status='Approved';
    }
    else{
      status='Pending';
    }
    return (
        <Tag
          value={status}
          style={{ fontSize: "11px" }}
          severity={getSeverity(status)}
        />
      );
  };
  const documentBodyTemplate=(rowData:any)=>{

    if((rowData.HcAdminStatus && rowData.Status && (rowData.HcAdminStatus!=='Pending'||rowData.Status==="Declined")) ){
    return  <Button type="button" icon="pi pi-file-pdf" rounded 
    onClick={()=> documentBodyTemplateDownload(rowData)}></Button>;
    }else{
      return "-";
    }
  };
  const documentBodyTemplateDownload = (rowData: any) => {

    axios({
        method: 'get',
        url: MIDDLE_WARE_BASE_URL() + DOCUMENT_PDF()+'?uuid='+rowData.LeaveUId,
        headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
        },
        responseType: 'blob'
    }).then(function (response) {
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(response.data);
        a.download = rowData.LeaveUId+'_'+rowData.EmployeeName+`.pdf`;
        document.body.appendChild(a);
        a.click();
        a.remove();
    });

   
          }
  
          const managerTemplate=(rowData:any)=>{

            if(rowData.ManagerName && rowData.ManagerNumber  ){
            return ( <>
            {
                    <div>{rowData.ManagerName  + " (" +  rowData.ManagerNumber + ")"}</div>
            }
        </>
        )
            }else{
              return "-";
            }
          };
          const downloadTemplate = (rowData: any) => {
            // if (rowData.Attachment === "" || rowData.Attachment === null || rowData.Attachment1 === "" || rowData.Attachment1 === null) {
            //     return <span>-</span>
            // }
        
            return (
                <>
                    {
                            <div>
                        {(rowData.Attachment !== "" || rowData.Attachment !== null) && <a download={rowData.AttachmentName}  href={rowData.Attachment} className="file-link" style={{color:"black"}} target="_blank" rel="noreferrer"> {rowData.AttachmentName} </a> } <br/>                     
                        {(rowData.Attachment1 !== "" || rowData.Attachment1 !== null) &&  <a download={rowData.AttachmentName1} href={rowData.Attachment1} className="file-link" target="_blank" rel="noreferrer"> {rowData.AttachmentName1} </a>}
                                  </div>
                    }
                </>
            );
        };
        

  return (
    <>
      <div id="middleLayout"
        
        style={{ margin: "20px 20px", padding: "0 5px", borderRadius: "5px" }}
      >
       <Panel header={ lang === "EN" ? "Leave Request Summary and Reporting Page" : "Статус на молбите за отпуск и Репорт"}>
       <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
        <DataTable
          value={leaveData}
          showGridlines
          sortMode="multiple"
          tableStyle={{ minWidth: "50rem" }}
          header={header}
          filters={filters}
          onFilter={(e: any) => setFilters(e.filters)}
          rows={10}
          paginator
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={[10, 25, 50,100]}
          emptyMessage={ lang === "EN" ? "No records found." : "Не са намерени записи"}
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          style={{ fontSize: "0.75rem" }}
          selection={selectedProducts}
          onSelectionChange={(e) => setSelectedProducts(e.value)}
        >
          <Column
            selectionMode="multiple"
            headerStyle={{ width: "2rem" }}
          ></Column>
          <Column
            field="EmployeeNo"
            sortable
            filter
            filterPlaceholder="Search"
            header={ lang === "EN" ? "Employee Number" : "Служебен номер"}
            style={{ minWidth: "1rem", maxWidth: "10rem" }}
            className="pe-1"
          ></Column>
          <Column field="EmployeeName"   filter
            filterPlaceholder="Search"sortable header={ lang === "EN" ? "Employee Name" : "Име на служител"}></Column>
          <Column field="StartDate"   
            filterPlaceholder="Search" header={ lang === "EN" ? "Start Date" : "Начална дата на отпуск"}></Column>
          <Column field="EndDate"   
            filterPlaceholder="Search"  header={ lang === "EN" ? "End Date" : "Крайна дата на отпуск"}></Column>
          <Column
            field="DaysCount"
            
            header={ lang === "EN" ? "No of Day/s Requested" : "Брой на заявените дни"}
          ></Column>
            <Column field="DaysAvailable"  header={ lang === "EN" ? "No of Days Avaialble" : "Брой налични дни или налични кредити"}></Column>
          <Column field="LeaveType" filter
            filterPlaceholder="Search" header={ lang === "EN" ? "Leave type" : "Тип отпуск"}></Column>
          {(gcaLevel === "HC" || gcaLevel === "PR") && <Column
            field="Attachment"
            sortable
            filter
            header={ lang === "EN" ? "Attachment" : "Приложени документи"}
            body={downloadTemplate}
          ></Column>  }
        
          <Column field="Comment"   header={ lang === "EN" ? "Comment" : "Коментари"}></Column>
          <Column  filter
            filterPlaceholder="Search"
            field="ManagerName"
            body={managerTemplate}
            header={ lang === "EN" ? "Manager’s Name / Supervisor’s Name" : "Име на мениджър/супервайзър"}
          > </Column>
           <Column
            field="Approvedby"
            
            filter
            filterPlaceholder="Search"
            header={ lang === "EN" ? "Actioned By" : "Отразена от"}
          ></Column>
           <Column
              field="NextApprover"
             // sortable
             filter
              header={ lang === "EN" ? "Pending Action" : "В очакване на действие от"}    style={{ minWidth: "1rem", maxWidth: "10rem" }}
              className="pe-1"
            ></Column>
          <Column
            field="DeclineReason"
            
            header={ lang === "EN" ? "Decline Reason" : "Причина за отказ"}
          ></Column>
        <Column
            field="Decision"
            body={statusBodyTemplate}
            header={ lang === "EN" ? "Decision" : "Бутон за решение Одобрено / Отказано"}
          ></Column> 
           <Column
            field="Document"
            body={documentBodyTemplate}
            header={ lang === "EN" ? "Document" : "Документ"}
          ></Column> 
        </DataTable>
        </Panel>
      </div>
    </>
  );
};

export default LeaveRequestSummary;
