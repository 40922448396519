import axios from "axios";
import { RESPONSETYPE,SCOPE,CLIENTID,GRANTTYPE } from "../../constants";
import {ClearStorage , TokenStorage} from "../../datasource/storage";
import {showLoader, hideLoader } from "../../datasource/msal";

let REDIRECTURI=process.env.REACT_APP_REDIRECT_URI?process.env.REACT_APP_REDIRECT_URI:"";


const getTokenRequestHeaders = () => {
    return {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/x-www-form-urlencoded"
        }
    };
};

export const sendAuthorizationRequest = () => {
      let authorizeRequest = `https://identity.ttec.com/as/authorization.oauth2?response_type=${ RESPONSETYPE }&scope=${ SCOPE }&redirect_uri=${ REDIRECTURI }&client_id=${ CLIENTID }`;
    window.location.href = authorizeRequest;   
};

export const sendTokenRequest = async (code) => {
    const body = [];
    body.push(`client_id=${ CLIENTID }`);
    body.push(`code=${ code }`);
    body.push(`grant_type=${ GRANTTYPE }`);
    body.push(`redirect_uri=${ REDIRECTURI }`);
    return await axios.post(`https://identity.ttec.com/as/token.oauth2`,body.join("&"), getTokenRequestHeaders())
        .then(response => {
            showLoader();
            if (response?.status !== 200) {
                return Promise.reject(new Error("Invalid status code received in the token response: "
                    + response?.status));
            }
           TokenStorage(response?.data);
           return response;

        }).catch((error) => {
            hideLoader();
            ClearStorage();
            return Promise.reject(error);
        });
};
