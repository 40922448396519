import "./spinner.scss";
import { ProgressSpinner } from 'primereact/progressspinner';

const Spinner = () => {
  return (
    <div className="fp-container">
      <ProgressSpinner style={{width: '60px', height: '60px'}} className="fp-loader" strokeWidth="6"  animationDuration=".8s" />
    </div>
  )
}

export default Spinner;