import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { MIDDLE_WARE_BASE_URL, UPDATE_EMPLOYEE_LEAVE_APPROVALS } from '../../constants';
import axios from '../../datasource/axios-instance'; 

const initialState = {
   LeaveUpdateDetailsStatus : null,
   LeaveUpdateDetailsState : null
};

export const  LeaveUpdateDetails = createAsyncThunk(
    'post/LeaveUpdateDetails', async(data : any) =>  {
       const response = await axios.patchRequest(MIDDLE_WARE_BASE_URL() + UPDATE_EMPLOYEE_LEAVE_APPROVALS()+"/"+ data.id,data);
       return response;
});

export const LeaveUpdateDetailsSlice = createSlice({
    name : 'LeaveUpdateDetails',
    initialState,
    reducers : {},
    extraReducers : (builder : any) => 
       builder
       .addCase(LeaveUpdateDetails.pending, (state : any) =>
       {
            state.LeaveUpdateDetailsState = 'pending';
       })
       .addCase(LeaveUpdateDetails.fulfilled, (state : any,action : any) =>
       {
           state.LeaveUpdateDetailsState = 'fulfilled';
           state.LeaveUpdateDetailsStatus = 'success';

       
        })
     
       .addCase(LeaveUpdateDetails.rejected, (state : any) =>
       {
            state.LeaveUpdateDetailsState = 'failed';
            state.LeaveUpdateDetailsStatus = 'fail';
       })
     
});

export const LeaveUpdateDetailsList = ((state : any) => state.LeaveUpdateDetails || {});
 
export default LeaveUpdateDetailsSlice.reducer;