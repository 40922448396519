export const LOGIN = "/";
export const LEAVEREQUEST = "/LeaveRequest";
export const INFO = "/info";
export const REQUESTSTATUS = "/RequestStatus";
export const MANAGERPAGE = "/Managerpage";
export const SENIORMANAGERAPPROVAL = "/SeniorManagerApproval";
export const DIRECTORAPPROVAL = "/DirectorApproval";
// export const REPORTINGPAGE = "/ReportingPage";
export const HCADMINPAGE = "/HcAdminPage";
export const LEAVEREQUESTSUMMARY='/LeaveRequestSummaryPage';
export const ACCESSDENIED = "/AccessDenied";
export const LOAD = "/Loading";
export const PAGENOTFOUND = "/PageNotFound";
export const LEAVEREQUESTORAPPROVALPAGE = "/LeaveApprovals";
export const LEAVEREQUESTORDOWNTHEAPPROVALPAGE = "/LeaveApprovalsDownTheChain";
export const LEGACYDATA = "/LegacyData";

export const MIDDLE_WARE_BASE_URL = () => `https://blm-prod.azurewebsites.net/api/v1/`;
//export const MIDDLE_WARE_BASE_URL = () => `https://localhost:7123/api/v1/`;
export const LEAVE_REQUEST_TYPES = () => `leaverequest/leavetypes`;
export const GET_EMPLOYEE_DETAILS = () => `leaverequest/getemployeeDetails`;
export const CREATE_LEAVE_REQUEST = () => `leaverequest/createleaverequest`;
export const GET_REQUESTSTATUS_DETAILS = () =>
  `leaverequest/getemployeeleaverequestsbyId`;
  export const GET_MANAGER_DETAILS = () =>
  `leaverequest/getemployeeleaverequests`;
  export const EMP_MANAGER_DETAILS = () =>
  `leaverequest/updateleaverequest`;
  export const GET_SR_MANAGERS_LIST = () =>
  `leaverequest/getsrmanagerslist`;
  export const APPROVE_DELEGATE_LIST = () =>
  `leaverequest/updatedelegatedmanager`;
  export const CANCEL_LEAVE = () =>
  `leaverequest`;
  export const DOCUMENT_PDF = () =>
  `leaverequest/generateleaverequestpdf`;
  export const HOLIDAY_DETAILS = () =>
  `leaverequest/getLeaveCount`;
  export const GET_EMPLOYEE_LEAVE_APPROVALS = () =>
  `leaverequest/getemployeeleaverequestdetails`;
  export const UPDATE_EMPLOYEE_LEAVE_APPROVALS = () =>
  `leaverequest/updateleaverequestdetails`;
  export const GET_EMPLOYEE_LEAVE_APPROVALS_BY_FILTER = () =>
  `leaverequest/getemployeeleaverequestdetailsbyfilter`;
  export const GET_INTIAL_ADMIN_DETAILS = () =>
  `leaverequest/getadmindetails`;
  export const LEGACY_DATA_REQUEST=()=>`leaverequest/getlegacydatadetails`;
export const LEGACY_DATA_PDF=()=>`leaverequest/generatelegacydatapdf`;

//---------------------==PINGID CONFIG==-------------------------------
  export const RESPONSETYPE = "code";
  export const SCOPE =  "openid";
  export const  CLIENTID =  "BulgariaLeaveManagement";
  export const GRANTTYPE = "authorization_code";
//----------------------------------------------------------------------
