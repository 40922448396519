import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { MIDDLE_WARE_BASE_URL, LEGACY_DATA_REQUEST } from '../../constants';
import axios from '../../datasource/axios-instance'; 

const initialState = {
    legacyDataTypeStatus : null,
    legacyDataTypeState : null,
    legacyDataTypeData : null
};

export const legacyDataType = createAsyncThunk(
    'get/legacyDataTypeData',
    async (data: any) => {
        const response = await axios.getRequest(
          MIDDLE_WARE_BASE_URL() +
          LEGACY_DATA_REQUEST() +
            "?searchparam=" +
            data.searchparams
        );
       return response;
});

export const legacyDataTypeDataSlice = createSlice({
    name : 'legacyDataTypeData',
    initialState,
    reducers : {},
    extraReducers : (builder : any) => 
       builder
       .addCase(legacyDataType.pending, (state : any) =>
       {
            state.legacyDataTypeState = 'pending';
       })
       .addCase(legacyDataType.fulfilled, (state : any,action : any) =>
       {
           state.legacyDataTypeState = 'fulfilled';
           state.legacyDataTypeStatus = 'success';
           state.legacyDataTypeData = action.payload.data;
        })
       .addCase(legacyDataType.rejected, (state : any) =>
       {
            state.legacyDataTypeState = 'failed';
            state.legacyDataTypeStatus = 'fail';
       })
});

export const selectlegacyDataTypeDetails = ((state : any) => state.legacyDataTypeData || {});
 
export default legacyDataTypeDataSlice.reducer;