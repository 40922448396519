import React, { useEffect, useRef, useState } from "react";
import { TieredMenu } from "primereact/tieredmenu";
import "./header.scss";
import Logo from "../../../assets/images/ttec-logo-white.png";
import { Button } from "primereact/button";
import {ClearStorage} from "../../../datasource/storage";
//let SUPPORTED_COUNTRY_IN=process.env.REACT_APP_SUPPORTED_COUNTRY_IN?process.env.REACT_APP_SUPPORTED_COUNTRY_IN:"";
//let SUPPORTED_COUNTRY_BG=process.env.REACT_APP_SUPPORTED_COUNTRY_BG?process.env.REACT_APP_SUPPORTED_COUNTRY_BG:"";


export default function HeaderSection() {
  const isAuthenticated = localStorage.getItem("AccessToken");
  //const country = localStorage.getItem("country");
 const gcaLevel = localStorage.getItem("gca");
  //const gcaLevel = 'U';
  const userMenu = useRef(null);
  const [selectedValue, setSelectedValue] = useState('EN');

  const handleOptionChange = (event) => {
      setSelectedValue(event.target.value);
      localStorage.setItem("language", event.target.value);
      window.location.reload();
  };

  useEffect(() => 
  {
    if(localStorage.getItem("language") !== null && localStorage.getItem("language") !== "")
    setSelectedValue(localStorage.getItem("language"));
    else
    localStorage.setItem("language", "EN");
  },[]);

   const items =

      gcaLevel === "HC" ?

        [{
          label: selectedValue === "EN" ? "New Leave Request" : "Нова молба",
          icon: "pi pi-fw pi-book",
          command: (e) => {
            window.location = "/leaverequest";
          },
        },
        {
          label: selectedValue === "EN" ? "Leave Status Page" : "Статус на заявките за отпуска",
          icon: "pi pi-fw pi-info-circle",
          command: (e) => {
            window.location = "/RequestStatus";
          },
        },
        {
          label: selectedValue === "EN" ? "Leave Request Summary and Reporting Page" : "Статус на молбите за отпуск и Репорт",
          icon: "pi pi-fw pi-id-card",
          command: (e) => {
            window.location = "/LeaveRequestSummaryPage";
          },
        },
       
        {
          label: selectedValue === "EN" ? "Requests pending my approval": "Молби към мен",
          icon: "pi pi-fw pi-user",
          command: (e) => {
            window.location = "/LeaveApprovals";
          },
        },
        {
          label: "Legacy Employee Search",
          icon: "pi pi-fw pi-user",
          command: (e) => {
            window.location = "/LegacyData";
          },
        },
          {
            separator: true,
          },

          {
            label: selectedValue === "EN" ? "Logout" : "Бутон за изход",
            icon: "pi pi-fw pi-power-off",
            command: () => handleLogout(),
          },
        ] :
        gcaLevel === "A" ? [
          {
            label: selectedValue === "EN" ? "New Leave Request" : "Нова молба",
            icon: "pi pi-fw pi-book",
            command: (e) => {
              window.location = "/leaverequest";
            },
          },
          {
            label: selectedValue === "EN" ? "Leave Status Page" : "Статус на заявките за отпуска",
            icon: "pi pi-fw pi-info-circle",
            command: (e) => {
              window.location = "/RequestStatus";
            },
          },
          {
            separator: true,
          },

          {
            label: selectedValue === "EN" ? "Logout" : "Бутон за изход",
            icon: "pi pi-fw pi-power-off",
            command: () => handleLogout(),
          }
        ] :
        gcaLevel === "D" ? [
         
            {
              label: selectedValue === "EN" ? "New Leave Request" : "Нова молба",
              icon: "pi pi-fw pi-book",
              command: (e) => {
                window.location = "/leaverequest";
              },
          },
          {
            label: selectedValue === "EN" ? "Leave Request Summary and Reporting Page" : "Статус на молбите за отпуск и Репорт",
            icon: "pi pi-fw pi-id-card",
            command: (e) => {
              window.location = "/LeaveRequestSummaryPage";
            },
          },
          {
            label: selectedValue === "EN" ? "Requests pending my approval": "Молби към мен",
            icon: "pi pi-fw pi-user",
            command: (e) => {
              window.location = "/LeaveApprovals";
            },
          },
            {
              label: selectedValue === "EN" ? "Requests down the approval path": "Молби надолу по веригата",
              icon: "pi pi-fw pi-user",
              command: (e) => {
                window.location = "/LeaveApprovalsDownTheChain";
              },
            },
         
          {
            separator: true,
          },

          {
            label: selectedValue === "EN" ? "Logout" : "Бутон за изход",
            icon: "pi pi-fw pi-power-off",
            command: () => handleLogout(),
          }
        ] :
        gcaLevel === "S" ? [{
            label: selectedValue === "EN" ? "New Leave Request" : "Нова молба",
            icon: "pi pi-fw pi-book",
            command: (e) => {
              window.location = "/leaverequest";
            },
          },
         
          {
            label: selectedValue === "EN" ? "Leave Status Page" : "Статус на заявките за отпуска",
            icon: "pi pi-fw pi-info-circle",
            command: (e) => {
              window.location = "/RequestStatus";
            },
          },
          {
            label: selectedValue === "EN" ? "Leave Request Summary and Reporting Page" : "Статус на молбите за отпуск и Репорт",
            icon: "pi pi-fw pi-id-card",
            command: (e) => {
              window.location = "/LeaveRequestSummaryPage";
            },
          },
          {
            label: selectedValue === "EN" ? "Requests pending my approval": "Молби към мен",
            icon: "pi pi-fw pi-user",
            command: (e) => {
              window.location = "/LeaveApprovals";
            },
          },
            {
              label: selectedValue === "EN" ? "Requests down the approval path": "Молби надолу по веригата",
              icon: "pi pi-fw pi-user",
              command: (e) => {
                window.location = "/LeaveApprovalsDownTheChain";
              },
            },
     
          {
            separator: true,
          },

          {
            label: selectedValue === "EN" ? "Logout" : "Бутон за изход",
            icon: "pi pi-fw pi-power-off",
            command: () => handleLogout(),
          }
        ] : (gcaLevel === "M") ? [{
            label: selectedValue === "EN" ? "New Leave Request" : "Нова молба",
            icon: "pi pi-fw pi-book",
            command: (e) => {
              window.location = "/leaverequest";
            },
          },
          {
            label: selectedValue === "EN" ? "Leave Status Page" : "Статус на заявките за отпуска",
            icon: "pi pi-fw pi-info-circle",
            command: (e) => {
              window.location = "/RequestStatus";
            },
          },
          {
            label: selectedValue === "EN" ? "Leave Request Summary and Reporting Page" : "Статус на молбите за отпуск и Репорт",
            icon: "pi pi-fw pi-id-card",
            command: (e) => {
              window.location = "/LeaveRequestSummaryPage";
            },
          },
          {
            label: selectedValue === "EN" ? "Requests pending my approval": "Молби към мен",
            icon: "pi pi-fw pi-user",
            command: (e) => {
              window.location = "/LeaveApprovals";
            },
          },
            {
              label: selectedValue === "EN" ? "Requests down the approval path": "Молби надолу по веригата",
              icon: "pi pi-fw pi-user",
              command: (e) => {
                window.location = "/LeaveApprovalsDownTheChain";
              },
            },
        
          {
            separator: true,
          },

          {
            label: selectedValue === "EN" ? "Logout" : "Бутон за изход",
            icon: "pi pi-fw pi-power-off",
            command: () => handleLogout(),
          }
        ] :
        gcaLevel === "PR" ? [{
            label: selectedValue === "EN" ? "New Leave Request" : "Нова молба",
            icon: "pi pi-fw pi-book",
            command: (e) => {
              window.location = "/leaverequest";
            },
          },
          {
            label: selectedValue === "EN" ? "Leave Status Page" : "Статус на заявките за отпуска",
            icon: "pi pi-fw pi-info-circle",
            command: (e) => {
              window.location = "/RequestStatus";
            },
          },
          {
            label: selectedValue === "EN" ? "Leave Request Summary and Reporting Page" : "Статус на молбите за отпуск и Репорт",
            icon: "pi pi-fw pi-id-card",
            command: (e) => {
              window.location = "/LeaveRequestSummaryPage";
            },
          },
     
          {
            separator: true,
          },

          {
            label: selectedValue === "EN" ? "Logout" : "Бутон за изход",
            icon: "pi pi-fw pi-power-off",
            command: () => handleLogout(),
          }
        ]:
        [{
            label: selectedValue === "EN" ? "New Leave Request" : "Нова молба",
            icon: "pi pi-fw pi-book",
            command: (e) => {
              window.location = "/leaverequest";
            },
          },
          {
            label: selectedValue === "EN" ? "Leave Request Summary and Reporting Page" : "Статус на молбите за отпуск и Репорт",
            icon: "pi pi-fw pi-id-card",
            command: (e) => {
              window.location = "/LeaveRequestSummaryPage";
            },
          },
          {
            label: selectedValue === "EN" ? "Leave Status Page" : "Статус на заявките за отпуска",
            icon: "pi pi-fw pi-info-circle",
            command: (e) => {
              window.location = "/RequestStatus";
            },
          },
          {
            label: selectedValue === "EN" ? "Requests pending my approval": "Молби към мен",
            icon: "pi pi-fw pi-user",
            command: (e) => {
              window.location = "/LeaveApprovals";
            },
          },
            {
              label: selectedValue === "EN" ? "Requests down the approval path": "Молби надолу по веригата",
              icon: "pi pi-fw pi-user",
              command: (e) => {
                window.location = "/LeaveApprovalsDownTheChain";
              },
            },
       
         
          {
            separator: true,
          },

          {
            label: selectedValue === "EN" ? "Logout" : "Бутон за изход",
            icon: "pi pi-fw pi-power-off",
            command: () => handleLogout(),
          }
        ]
  const handleLogout = async () => {
    ClearStorage();
    let logoutRequest = `https://identity.ttec.com/idp/startSLO.ping`;
    window.location.href = logoutRequest; 
  }

  return (
    <>
      <div>
        <header className="py-3 mb-4 border-bottom headerSectionbox">
          <div className="row">
            <div className="col-md-9">
              <div className="container-fluid d-flex flex-wrap justify-content-center">
                <a
                  href="/"
                  className="d-flex align-items-center mb-3 mb-lg-0 me-lg-auto text-dark text-decoration-none"
                >
                  <img src={Logo} width={"70px"} alt="TTEC" />
                </a>
                <h3 className="d-flex align-items-center mb-3 mb-lg-0 me-lg-auto text-light text-decoration-none">
                {selectedValue === "EN" ? "Bulgaria Leave Management" : "Управление на отпуски - България"}       
                </h3>
              </div>
            </div>
            <div className="col-md-3">
              {isAuthenticated && (
                <div id="headerList">
                  <b style ={{color:"white"}}>{selectedValue === "EN" ? "Language" : "Език"} :</b> <select value={selectedValue} onChange={handleOptionChange}>
                        <option value="BG" className={selectedValue === 'BG' ? 'selected-option' : ''}>BG</option>
                        <option value="EN" className={selectedValue === 'EN' ? 'selected-option' : ''}>EN</option>
                    </select>
                    &nbsp; &nbsp; &nbsp;
                  <TieredMenu
                    model={items}
                    popup
                    ref={userMenu}
                    id="overlay_tmenu"
                  />
                  <Button
                    className="p-button-rounded p-button-outlined headerBtn"
                    icon="pi pi-ellipsis-h"
                    onClick={(event) => userMenu.current.toggle(event)}
                    aria-haspopup
                    aria-controls="overlay_tmenu"
                  />
                </div>
              )}
            </div>
          </div>
        </header>
        <div className="divSpace"></div>
      </div>
    </>
  );
}