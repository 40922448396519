import React from "react";
import "../footer/footer.scss";
import { useState, useEffect } from 'react';


export default function FooterSection() {
  const [lang, setLang] = useState("EN");
  useEffect(() => {
    if (localStorage.getItem("language") === "BG") {
        setLang("BG");
    }
    else {
        setLang("EN");
    }
}, []);
  return (
    <div>
      <footer className="footer">
          <p>© 2024 TTEC, All rights reserved. <a href="mailto:bghcportal@ttec.com" target="_blank" rel="noreferrer noopener">{lang === "EN" ? "For technical issues, please contact us" : "за технически въпроси, свържете се с нас"}</a></p>
      </footer>
    </div>
  );
}