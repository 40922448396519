import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useEffect, useRef, useState } from "react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";
import {
  getRequestStatusDetails,
  getRequestStatusDetailsDetails,
} from "./RequestStatusSlice";
import { deleteRequestStatusDetailsDetails, deleteRequestStatusDetails } from "./RequestDeleteSlice";
import { useDispatch, useSelector } from "react-redux";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import moment from "moment";



const RequestStatus = () => {

  const dispatch = useDispatch();
  const [data, setData] = useState<any>([]);
  const [lang, setLang] = useState("EN");
  const toast = useRef<any>(null);
  //const [selectedProducts, setSelectedProducts] = useState<any>(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    EmployeeNo: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },

    EmployeeName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    startDate: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    endDate: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },

    LeaveType: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    Comment: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    Approvedby: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    DeclineReason: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    Status: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    activity: { value: null, matchMode: FilterMatchMode.BETWEEN },
  });
  const { requestStatusDetailsData, requestStatusState } = useSelector(
    getRequestStatusDetailsDetails
  );
  const { deleteRequestStatusState } = useSelector(
    deleteRequestStatusDetailsDetails
  );
  

  const getSeverity = (status: any) => {
    switch (status) {
      case "Declined":
        return "danger";

      case "Accepted":
        return "success";

      case "Pending":
        return "info";

         case "Withdrawn":
           return "warning";

      case "renewal":
        return null;
    }
  };

  ///// api call

  useEffect(() => {
    dispatch<any>(getRequestStatusDetails());
  }, [dispatch]);

  useEffect(() => {
    if (requestStatusState === 'fulfilled') {
      setData(requestStatusDetailsData);
    }

  }, [requestStatusDetailsData, requestStatusState]);

  useEffect(()=>{
    if (deleteRequestStatusState === 'fulfilled') {
      toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Leave Request Cancelled Sucessfully', life: 3000 });
            
      dispatch<any>(getRequestStatusDetails());
    } if(deleteRequestStatusState === 'failed'){
      toast.current.show({ severity: 'error', summary: 'Error Message', detail: 'Error Cancel Leave Request', life: 3000 });
           
    }
  },[deleteRequestStatusState,dispatch]);


  useEffect(() => {
    if (localStorage.getItem("language") === "BG") {
      setLang("BG");
    }
    else {
      setLang("EN");
    }
  }, []);

  const onGlobalFilterChange = (event: any) => {
    const value = event.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    //const value = filters["global"] ? filters["global"].value : "";

    return (
      <div className="flex justify-content-between" style={{ display: "flex" }}>
        {/* <span>Request Status</span> */}
        <span className="p-input-icon-right ">
          <i className="pi pi-search" />
          <InputText
            type="search"
            value={globalFilterValue}
            onChange={(e) => onGlobalFilterChange(e)}
            placeholder={lang === "EN" ? "Global Search" : "Глобално търсене"}
          />
        </span>
      </div>
    );
  };
  const header = renderHeader();
  function cancelLeave(uuid: any,startDate:any,endDate:any) {
    let sdate=moment(startDate,'DD-MM-YYYY');
    let edate=moment(endDate,'DD-MM-YYYY');
    const data = {
      "uid": uuid,
      "startDate":sdate.format('DD-MMM-YYYY'),
      "endDate":edate.format('DD-MMM-YYYY')
    };
    dispatch<any>(deleteRequestStatusDetails(data));
  }

  const statusBodyTemplate = (rowData: any) => {

    if (rowData.Status === 'Pending') {
      return (
        <Button
          type="button"

          label={lang === "EN" ? "Cancel" : "анулиране"}

          onClick={() => cancelLeave(rowData.LeaveUId,rowData.startDate,rowData.endDate)}
        />
      );
    }else if(rowData.Status ==='Accepted'){
      return (
        <Tag
          value={'Approved'}
          style={{ fontSize: "11px" }}
          severity={getSeverity(rowData.Status)}
        />
      );
    } 
    else {
      return (
        <Tag
          value={rowData.Status}
          style={{ fontSize: "11px" }}
          severity={getSeverity(rowData.Status)}
        />
      );
    }
  };

  const downloadTemplate = (rowData: any) => {
    // if (rowData.Attachment === "" || rowData.Attachment === null) {
    //   return <span>-</span>
    // }

    return (
      <>
        {
          <div>
                        {(rowData.Attachment !== "" || rowData.Attachment !== null) && <a download={rowData.AttachmentName}  href={rowData.Attachment} className="file-link" style={{color:"black"}} target="_blank" rel="noreferrer"> {rowData.AttachmentName} </a> } <br/>                     
                        {(rowData.Attachment1 !== "" || rowData.Attachment1 !== null) &&  <a download={rowData.AttachmentName1} href={rowData.Attachment1} className="file-link" target="_blank" rel="noreferrer"> {rowData.AttachmentName1} </a>}
          </div>
        }
      </>
    );
  };

  return (
    <>
      <div
        className="card"
        id="middleLayout"
        style={{ margin: "20px", padding: "0 5px", borderRadius: "5px" }}
      >
        <Panel header={lang === "EN" ? "Leave Request Status" : "Статус на заявките за отпуска"}>  </Panel>
        <DataTable
          value={data}
          showGridlines
          sortMode="multiple"
          tableStyle={{ minWidth: "50rem" }}
          header={header}
          filters={filters}
          onFilter={(e: any) => setFilters(e.filters)}
          rows={10}
          paginator
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={[10, 25, 50,100]}
          emptyMessage={lang === "EN" ? "No Records found." : "Не са намерени записи"}
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          style={{ fontSize: "13px", textAlign: "right" }}
        //   selectionMode={rowClick ? null : "checkbox"}
        //selection={selectedProducts}
        //onSelectionChange={(e) => setSelectedProducts(e.value)}
        >
         
          <Column
            field="EmployeeNo"
            sortable
            filter
            filterPlaceholder="Search"
            header={lang === "EN" ? "Employee Number" : "Служебен номер"}
            style={{ minWidth: "1rem", maxWidth: "10rem" }}
            className="pe-1"
          ></Column>
          <Column field="EmployeeName" filter
            filterPlaceholder="Search" sortable header={lang === "EN" ? "Employee Name" : "Име на служител"}></Column>
          <Column field="startDate" 
            filterPlaceholder="Search"  header={lang === "EN" ? "Start Date" : "Начална дата на отпуск"}></Column>
          <Column field="endDate" 
            filterPlaceholder="Search"  header={lang === "EN" ? "End Date" : "Крайна дата на отпуск"}></Column>
          <Column
            field="DaysCount"
            sortable
            header={lang === "EN" ? "No of Day/s Requested" : "Брой на заявените дни"}
          ></Column>
          <Column field="LeaveType" filter
            filterPlaceholder="Search" sortable header={lang === "EN" ? "Leave type" : "Тип отпуск"}></Column>
          <Column
            field="Attachment"
            sortable
            filter
            header={lang === "EN" ? "Attachment" : "Приложени документи"}
            body={downloadTemplate}
          ></Column>
          <Column field="Comment" sortable header={lang === "EN" ? "Comment" : "Коментари"}></Column>
          <Column
            field="Approvedby"
            sortable
            filter
            filterPlaceholder="Search"
            header={ lang === "EN" ? "Actioned By" : "Отразена от"}
          ></Column>
           <Column
              field="NextApprover"
             // sortable
             filter
              header={ lang === "EN" ? "Pending Action" : "В очакване на действие от"}   style={{ minWidth: "1rem", maxWidth: "10rem" }}
              className="pe-1"
            ></Column>
          <Column
            field="DeclineReason"
            sortable
            header={lang === "EN" ? "Decline Reason" : "Причина за отказ"}
          ></Column>
          <Column
            field="Decision"
            body={statusBodyTemplate}
            header={lang === "EN" ? "Decision" : "Бутон за решение Одобрено / Отказано"}
          ></Column>
        </DataTable>
      
        <Toast ref={toast} />
      </div>
    </>
  );
};

export default RequestStatus;
