import React from "react";
import { LEAVEREQUEST } from "../../constants";
import './NotFound.scss';

export const NotFound = () => {
  return (
    <>
    <div className="">
       <div id="error-page">
         <div className="content">
            <h2 className="errorheader" >404</h2>
            <h4 data-text="Opps! Page not found">
               Opps! Page not found
            </h4>
            <p>
               Sorry, the page you're looking for doesn't exist.
            </p>
            <div className="btns">
               <a href={LEAVEREQUEST}>return home</a>
            </div>
         </div>
      </div>
      </div>
    </>
  );
};
