import React from "react";
import {Outlet, Navigate} from "react-router-dom";
import { LOGIN } from '../constants'; //ACCESSDENIED
//let SUPPORTED_COUNTRY_IN=process.env.REACT_APP_SUPPORTED_COUNTRY_IN?process.env.REACT_APP_SUPPORTED_COUNTRY_IN:"";
//let SUPPORTED_COUNTRY_BG=process.env.REACT_APP_SUPPORTED_COUNTRY_BG?process.env.REACT_APP_SUPPORTED_COUNTRY_BG:"";

const ProtectedRoute = (props: any) => {  //ProtectedRouteType
    let isAuthenticated = localStorage.getItem("AccessToken");
   // let country = localStorage.getItem("country");
  //    if(isAuthenticated && country !== null && (country !== SUPPORTED_COUNTRY_IN && country !== SUPPORTED_COUNTRY_BG))
  //    {
	//  return  <Navigate to={ACCESSDENIED} />
  //    }
  //    else
  //    {
       return isAuthenticated? <Outlet /> : <Navigate to={LOGIN} />
    // }
}

export default ProtectedRoute;