import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {hideLoader} from '../../datasource/msal'; 
import './initialLoadPage.scss';
import { LEAVEREQUEST,  LEAVEREQUESTORAPPROVALPAGE } from "../../constants";// ACCESSDENIED,MANAGERPAGE, SENIORMANAGERAPPROVAL, DIRECTORAPPROVAL, HCADMINPAGE,
import {MIDDLE_WARE_BASE_URL,GET_INTIAL_ADMIN_DETAILS} from "../../constants";
import axios from '../../datasource/axios-instance';

export function InitialLoadPage() {

    const token: any = React.useRef(localStorage.getItem("AccessToken") || '');
    const gcaLevel: any = React.useRef(localStorage.getItem("gca") || '');
    
    const navigate = useNavigate();

    const getAdminDetails = async (oracleId : string) => {
  
    const res : any = await new Promise((resolve, reject) => {
        let url=MIDDLE_WARE_BASE_URL() + GET_INTIAL_ADMIN_DETAILS() +
        "?oracleId=" + oracleId ;
      try {
        axios.getRequest(url) 
          .then((res: any) => {
            hideLoader();
            resolve(res);
          })
          .catch((err: any) => {
            hideLoader();
            resolve(err);
          });
      } catch (error) {
        hideLoader();
        reject(error);
      }
    });
    if(res!==undefined && res?.data !==undefined && res?.data[0]?.designation !== null)
    {
      var gcaLevel = res?.data[0]?.designation;
      localStorage.setItem("gca", res?.data[0]?.designation);
      if(gcaLevel === "PR"){
        navigate(LEAVEREQUEST); 
      }else{
        navigate(LEAVEREQUESTORAPPROVALPAGE);
      }
       window.location.reload();
    }
  };

  useEffect(() => {
    getAdminDetails(localStorage.getItem("oracleId")  || '');
    //getAdminDetails('8125584');
  })



    useEffect(() => {
       
        if (token.current !== "") {  
          if(gcaLevel.current === "A")         
                navigate(LEAVEREQUEST);
                else if(gcaLevel.current === "HC" || gcaLevel.current === "M" || gcaLevel.current === "S" || gcaLevel.current === "D") 
                 navigate(LEAVEREQUESTORAPPROVALPAGE);        
                else{
                navigate(LEAVEREQUEST);     
                }
            
            hideLoader();
        }
    
    }, [token,navigate,gcaLevel]) 

    return (
    <div className = "clsLoader" >
        Loading data...Please wait...
    </div>
    )
}


