import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  MIDDLE_WARE_BASE_URL,
  GET_EMPLOYEE_LEAVE_APPROVALS_BY_FILTER,
} from "../../constants";
import axios from "../../datasource/axios-instance";

const initialState = {
  LeaveApprovalDataStatusByFilter: null,
  LeaveApprovalDataStateByFilter: null,
  LeaveApprovalDataByFilter: null,
};

export const LeaveApprovalDetailsByFilter = createAsyncThunk(
  "get/getLeaveApprovalDetailsByFIlter",
  async (data: any) => {
    const response = await axios.getRequest(
      MIDDLE_WARE_BASE_URL() +
      GET_EMPLOYEE_LEAVE_APPROVALS_BY_FILTER() +
        "?oracleId=" +
        data.oracleId +"&requestedby=" + data.requestedby+"&approvalsListingType="+data.approvalsListingTypeVal+"&startDate="+data.startDate+"&endDate="+data.endDate+"&decisionFilter="+data.filter
    );
    return response;
  }
);

export const getLeaveApprovalDetailsByFilterSlice = createSlice({
  name: "getLeaveApprovalDetailsByFilter",
  initialState,
  reducers: {},
  extraReducers: (builder: any) =>
    builder
      .addCase(LeaveApprovalDetailsByFilter.pending, (state: any) => {
        state.LeaveApprovalDataStateByFilter = "pending";
      })
      .addCase(LeaveApprovalDetailsByFilter.fulfilled, (state: any, action: any) => {
        state.LeaveApprovalDataStateByFilter = "fulfilled";
        state.LeaveApprovalDataStatusByFilter = "success";
        state.LeaveApprovalDataByFilter = action.payload.data;
      })
      .addCase(LeaveApprovalDetailsByFilter.rejected, (state: any) => {
        state.LeaveApprovalDataStateByFilter = "failed";
        state.LeaveApprovalDataStatusByFilter = "fail";
      }),
});

export const selectLeaveApprovalDetailsByFilter = (state: any) =>
  state.getLeaveApprovalDetailsByFilter || {};

export default getLeaveApprovalDetailsByFilterSlice.reducer;
