import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { MIDDLE_WARE_BASE_URL, HOLIDAY_DETAILS } from '../../constants';
import axios from '../../datasource/axios-instance'; 

const initialState = {
    leaveHolidayTypeStatus : null,
    leaveHolidayTypeState : null,
    leaveHolidayTypeData : null
};

export const leaveHolidayTypes = createAsyncThunk(
    'get/leaveHolidayTypes', async(data:any) =>  {
       const response = await axios.getRequest(MIDDLE_WARE_BASE_URL() + HOLIDAY_DETAILS()+'?startdate='+data.stdate+'&enddate='+data.edate+'&oracleId='+data.oracleIdVal);
       return response;
});

export const leaveHolidaysCheckSlice = createSlice({
    name : 'leaveHolidayTypes',
    initialState,
    reducers : {},
    extraReducers : (builder : any) => 
       builder
       .addCase(leaveHolidayTypes.pending, (state : any) =>
       {
            state.leaveHolidayTypeState = 'pending';
       })
       .addCase(leaveHolidayTypes.fulfilled, (state : any,action : any) =>
       {
           state.leaveHolidayTypeState = 'fulfilled';
           state.leaveHolidaysCheckSlice = 'success';
           state.leaveHolidayTypeData = action.payload.data;
        })
       .addCase(leaveHolidayTypes.rejected, (state : any) =>
       {
            state.leaveHolidayTypeState = 'failed';
            state.leaveHolidaysCheckSlice = 'fail';
       })
});

export const selectleaveHolidayDetails = ((state : any) => state.leaveHolidayTypes || {});
 
export default leaveHolidaysCheckSlice.reducer;