import React, { useEffect, useState } from "react";
import "./login.scss";
import { sendAuthorizationRequest } from './tokenService';
import Logo from "../../assets/images/bulgaria.png";
import Flag from "../../assets/images/ttecLogo-black.png";
import {showLoader} from '../../datasource/msal';
import HeaderSection from "../../components/layouts/header/header"; 
import FooterSection from "../../components/layouts/footer/footer";

const Login = () => {   

  const showSpinner =  React.useRef(localStorage.getItem("isApiCalled") || null);
  const [selectedValue, setSelectedValue] = useState<any>('EN');

  const handleOptionChange = (event : any) => {
      setSelectedValue(event.target.value);
      localStorage.setItem("language", event.target.value);
  };

  useEffect(() => 
  {
    if(localStorage.getItem("language") !== null && localStorage.getItem("language") !== "")
    setSelectedValue(localStorage.getItem("language"));
    else
    localStorage.setItem("language", "EN");
  },[]);

  useEffect(() => {
    if(showSpinner.current === "true")
    {
      showLoader();
    }
},[showSpinner]);

  return <div className="body">
       { localStorage.getItem("isApiCalled") === "true" && <HeaderSection /> }
    <div className="container-fluid justify-content-center">
     { (localStorage.getItem("isApiCalled") === "false" || localStorage.getItem("isApiCalled") === null) && 
      <div className="row ps-3 mb-3">
        <div className="logo col-xs-12 col-sm-12 col-md-12 col-lg-6">
        
    
        </div>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
          <div className="loginLayout">
            <p>  <span><img src={Flag} width={"70px"} alt="TTEC" /></span><img src={Logo} width={"70px"} alt="TTEC" />
            <b>{selectedValue === "EN" ?  "Language" : "Език" } :</b> <select value={selectedValue} onChange={handleOptionChange}>
                        <option value="BG" className={selectedValue === 'BG' ? 'selected-option' : ''}>БГ</option>
                        <option value="EN" className={selectedValue === 'EN' ? 'selected-option' : ''}>EN</option>
                    </select>
                    </p>
            <h2 className="heading mt-2">{selectedValue === "EN" ?  "Login" : "Вписване" }</h2>
            <h6 className="heading mt-2">{selectedValue === "EN" ? "Sign in with your organization account" : "Впишете се с потребителския профил на вашата организация" }</h6>
            <button className="btnLogin col col-12 info"  onClick={sendAuthorizationRequest}>{selectedValue === "EN" ? "Login" : "Вписване"}</button>

          </div>
        </div>
      </div>
     }
    </div> 
    { localStorage.getItem("isApiCalled") === "true" && <FooterSection /> } 
  </div>
}

export default Login;