import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useEffect, useState, useRef } from "react";
import { Calendar } from "primereact/calendar";
import { FilterMatchMode } from "primereact/api"; //FilterOperator
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import { useDispatch, useSelector } from "react-redux";
import {
  LeaveApprovalDetails,
  selectLeaveApprovalDetails,
} from "../LeaveApprovalOrRejectPage/LeaveApprovalOrRejectPageSlice";
import {
  LeaveApprovalDetailsByFilter,
  selectLeaveApprovalDetailsByFilter,
} from "../LeaveApprovalOrRejectPage/LeaveApprovalOrRejectByFilterSlice";
import {
  LeaveUpdateDetailsList,
  LeaveUpdateDetails,
} from "../LeaveApprovalOrRejectPage/UpdateLeaveApprovalSlice";
import { Tag } from "primereact/tag";
import { Toolbar } from "primereact/toolbar";
import {
  deleteRequestStatusDetailsDetails,
  deleteRequestStatusDetails,
} from "../RequestStatus/RequestDeleteSlice";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import moment from "moment";
import { Dropdown } from "primereact/dropdown";

import "./LeaveApprovalOrRejectPage.scss";

const LeaveApprovalOrRejectPage = () => {

  const dispatch = useDispatch();
  const [data, setData] = useState<any>([]);
  const [selectedProducts, setSelectedProducts] = useState<any>(null);
  const [Alert, setAlert] = useState(true);
  const [AlertType, setAlertType] = useState('');
  const toast = useRef<any>(null);
  const [displayBasic, setDisplayBasic] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState("");
  let [remarks, setRemarks] = useState("");
  const [lang, setLang] = useState("EN");
 /*  const [designations] = useState([
    "Manager"
  ]); */
  moment.locale("en");

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    EmployeeNo: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    EmployeeName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    StartDate: { value: null, matchMode: FilterMatchMode.CONTAINS },
    EndDate: { value: null, matchMode: FilterMatchMode.CONTAINS },
    DaysCount: { value: null, matchMode: FilterMatchMode.EQUALS },
    LeaveType: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    Comment: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    Approvedby: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    DeclineReason: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    Status: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  //  Designation: { value: null, matchMode: FilterMatchMode.EQUALS },
    Attachment: { value: null, matchMode: FilterMatchMode.CONTAINS },
    //  activity: { value: null, matchMode: FilterMatchMode.BETWEEN },
  });

  const { LeaveUpdateDetailsState } = useSelector(LeaveUpdateDetailsList);
  const { LeaveApprovalData, LeaveApprovalDataState } = useSelector(
    selectLeaveApprovalDetails
  );
  const { LeaveApprovalDataByFilter, LeaveApprovalDataStateByFilter } =
    useSelector(selectLeaveApprovalDetailsByFilter);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const dialogFuncMap = {
    displayBasic: setDisplayBasic,
  };
  const { deleteRequestStatusState } = useSelector(
    deleteRequestStatusDetailsDetails
  );
  const [dates, setDates] = useState("");

  const getSeverity = (status: any) => {
    switch (status) {
      case "Declined":
        return "danger";

      case "Accepted":
        return "success";

      case "Pending":
        return "info";

      case "renewal":
        return null;
    }
  };

  const isSelectable = (data: any) => data.Status !== "Declined"; //data.Status !== "Accepted" &&

  const isRowSelectable = (event: any) =>
    event.data ? isSelectable(event.data) : true;

  const rowClassName = (data: any) => (isSelectable(data) ? "" : "p-disabled");

  const [selectedDecision, setSelectedDecision] = useState("Pending");

  const decisions = [
   // { label: "Declined(Отказана)", value: "Declined" },
    { label: "Accepted(Одобрена)", value: "Accepted" },
    { label: "Pending(В очакване на решение)", value: "Pending" },
  ];

  useEffect(() => {
    if (localStorage.getItem("language") === "BG") {
      setLang("BG");
    } else {
      setLang("EN");
    }
  }, []);



  useEffect(() => {
    var data = {
      oracleId: localStorage.getItem("oracleId"),
      requestedby: localStorage.getItem("gca"),
      approvalsListingTypeVal: "Direct",
    };
    dispatch<any>(LeaveApprovalDetails(data));
  }, [dispatch]);

  useEffect(() => {
    if (LeaveApprovalDataState === "fulfilled") {
      setData(LeaveApprovalData);
    }
  }, [LeaveApprovalData, LeaveApprovalDataState]);

  useEffect(() => {
    if (deleteRequestStatusState === "fulfilled") {
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Leave Request Cancelled Sucessfully",
        life: 10000,
      });
      var data = {
        oracleId: localStorage.getItem("oracleId"),
        requestedby: localStorage.getItem("gca"),
        approvalsListingTypeVal: "Direct",
      };
      dispatch<any>(LeaveApprovalDetails(data));
    }
    if (deleteRequestStatusState === "failed") {
      toast.current.show({
        severity: "error",
        summary: "Error Message",
        detail: "Error Cancel Leave Request",
        life: 5000,
      });
    }
  }, [deleteRequestStatusState, dispatch]);

  useEffect(() => {
    if (LeaveUpdateDetailsState === "fulfilled" && Alert) {
      setDates("");
      setRemarks("");
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Leave Request "+AlertType+" sent Sucessfully",
        life: 5000,
      });
      setAlert(false);
      setAlertType("");
      setSelectedProducts(null);
      setSelectedDecision("Pending");
      var data1 = {
        oracleId: localStorage.getItem("oracleId"),
        requestedby: localStorage.getItem("gca"),
        approvalsListingTypeVal: "Direct",
      };
      dispatch<any>(LeaveApprovalDetails(data1));
    }
    if (LeaveUpdateDetailsState === "failed" && Alert) {
      toast.current.show({
        severity: "error",
        summary: "Error Message",
        detail: "Error submitting Leave Request",
        life: 5000,
      });
      setAlert(false);
      setAlertType("");
      setSelectedProducts(null);
      setDates("");
      setSelectedDecision("Pending");

      var data = {
        oracleId: localStorage.getItem("oracleId"),
        requestedby: localStorage.getItem("gca"),
        approvalsListingTypeVal: "Direct",
      };
      dispatch<any>(LeaveApprovalDetails(data));
    }
  }, [LeaveUpdateDetailsState, Alert, dispatch,AlertType]);

 
  function handleRejectBulk() {
    dialogFuncMap["displayBasic"](true);
    setApprovalStatus("multiple");
  }
  function BulkAcceptRecords() {
    let acceptIds = "";
    let startDates = "";
    let endDates = "";
    let oracleIds = "";
    selectedProducts.forEach((e: { Id: any; StartDate: any; EndDate: any, EmployeeNo : string }) => {
      const sdateVal = moment(e.StartDate, "DD-MM-YYYY");
      const edateval = moment(e.EndDate, "DD-MM-YYYY");
      acceptIds = acceptIds + "," + e.Id;
      startDates = startDates + "," + sdateVal.format("DD-MMM-YYYY");
      endDates = endDates + "," + edateval.format("DD-MMM-YYYY");
      oracleIds = oracleIds + "," + (e.EmployeeNo).trim();
    });

    //Remove first comma from acceptIds string
    acceptIds = acceptIds.replace(/^,/, "");
    startDates = startDates.replace(/^,/, "");
    endDates = endDates.replace(/^,/, "");
    oracleIds = oracleIds.replace(/^,/, "");

    const data = {
      approvalStatus: "Accepted",
      approvedBy: localStorage.getItem("gca"),
      approvalOracleId: localStorage.getItem("oracleId"),
      declineReason: "",
      startDate: startDates,
      endDate: endDates,
      id: acceptIds,
      oracleIds : oracleIds
    };
   // console.log(data.startDate, data.endDate, data.oracleIds);
   dispatch<any>(LeaveUpdateDetails(data));
   setAlert(true);
   setAlertType("Approval");
  }
  function updateapproval() {
   if (remarks !== "" && approvalStatus === "multiple") {
      let declineIds = "";
      let startDatesVal = "";
      let endDatesVal = "";
      let oracleIds = "";
      selectedProducts.forEach(
        (e: { Id: any; StartDate: any; EndDate: any, EmployeeNo : any }) => {
          let dateS = moment(e.StartDate, "DD-MM-YYYY");
          let dateE = moment(e.EndDate, "DD-MM-YYYY");
          declineIds = declineIds + "," + e.Id;
          startDatesVal = startDatesVal + "," + dateS.format("DD-MMM-YYYY");
          endDatesVal = endDatesVal + "," + dateE.format("DD-MMM-YYYY");
          oracleIds = oracleIds + "," + (e.EmployeeNo).trim();
        }
      );
      //Remove first comma from declineIds string
      declineIds = declineIds.replace(/^,/, "");
      startDatesVal = startDatesVal.replace(/^,/, "");
      endDatesVal = endDatesVal.replace(/^,/, "");
      oracleIds = oracleIds.replace(/^,/, "");

      const data = {
        approvalStatus: "Declined",
        approvedBy: localStorage.getItem("gca"),
        approvalOracleId: localStorage.getItem("oracleId"),
        declineReason: remarks,
        startDate: startDatesVal,
        endDate: endDatesVal,
        id: declineIds,
        oracleIds : oracleIds
      };
   // console.log(data.startDate, data.endDate, data.oracleIds);
      dispatch<any>(LeaveUpdateDetails(data));

      setAlert(true);
      setAlertType("Declined");
      onHide("displayBasic");
    } else {
      toast.current.show({
        severity: "info",
        summary: "Alert Message",
        detail: "Please enter decline reason",
        life: 5000,
      });
    }
  }
  const onHide = (name: any) => {
    dialogFuncMap["displayBasic"](false);
  };
  const onGlobalFilterChange = (e: any) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };


  const clearDecision = () => {
    setSelectedDecision("Pending");
    setDates("");
    var data = {
      oracleId: localStorage.getItem("oracleId"),
      requestedby: localStorage.getItem("gca"),
      approvalsListingTypeVal: "Direct",
    };
    dispatch<any>(LeaveApprovalDetails(data));
  };
  const checkSplChar = (e: any) => {
    let key = e.key;
    const arr = ["'", "~", "`", '"'];
    // Check if key pressed is a special character
    if (arr.includes(key)) {
      // Restrict the special characters
      e.preventDefault();
      return false;
    }
  };
  const checkPasteChar = (e: any) => {
    e.preventDefault();
  };
  const handleCancel = (id: any, stDate: any, enDate: any) => {
     let sDateNum = moment(stDate, "DD-MM-YYYY");
     let eDateNum = moment(enDate, "DD-MM-YYYY");
    //debugger;
    var data = {
      uid: id,
      startDate: moment(sDateNum).format("DD-MMM-YYYY"),
      endDate: moment(eDateNum).format("DD-MMM-YYYY"),
    };
   // console.log(data.startDate, data.endDate);
    dispatch<any>(deleteRequestStatusDetails(data));
  };


  const dateFilterFunction = () => {
    let stDate = moment(dates[0]);
    let edDate = moment(dates[1]);
    let listingType = "";
    const gcaValue = localStorage.getItem("gca");
    if (gcaValue === "HC") {
      listingType = "InDirect";
    } else {
      listingType = "Direct";
    }
    var data = {
      oracleId: localStorage.getItem("oracleId"),
      requestedby: localStorage.getItem("gca"),
      approvalsListingTypeVal: listingType,
      startDate: stDate.format("YYYY-MM-DD"),
      endDate: edDate.format("YYYY-MM-DD"),
      filter: selectedDecision,
    };
    dispatch<any>(LeaveApprovalDetailsByFilter(data));
  };
  useEffect(() => {
    if (LeaveApprovalDataStateByFilter === "fulfilled") {
      setData(LeaveApprovalDataByFilter);
    }
  }, [LeaveApprovalDataByFilter, LeaveApprovalDataStateByFilter]);

  const leftToolbarTemplate = () => {
    const gcaVal = localStorage.getItem("gca");
    if (gcaVal === "HC") {
      return (
        <div className="row">
          <div className="col-3">
            <label className="p-mr-1">
              {lang === "EN" ? "Date Range" : "Период"}{" "}
            </label>
            <Calendar
              value={dates}
              onChange={(e: any) => setDates(e.value)}
              dateFormat="dd/mm/yy"
              selectionMode="range"
              readOnlyInput
            />
          </div>
          <div className="col-3">
            <label className="p-mr-1">
              {lang === "EN" ? "Decision" : "Решение"}
            </label>
            <Dropdown
              value={selectedDecision}
              onChange={(e) => setSelectedDecision(e.value)}
              options={decisions}
              placeholder="Select Decision Filter"
              className="w-full md:w-14rem"
            />
          </div>
          <div className="col-3 pt-4">
            <Button
              type="button"
              icon="pi pi-filter-icon "
              label={lang === "EN" ? "Filter" : "Филтър"}
              // outlined
              disabled={!dates}
              onClick={dateFilterFunction}
              className="mt-3 mt-md-0 ms-md-4"
            />
          </div>
          <div className="col-3 pt-4">
            <Button
              type="button"
              label={lang === "EN" ? "Clear" : "Изчистете"}
              outlined
              className="mt-3 mt-md-0 ms-md-4"
              onClick={clearDecision}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="row">
          <div className="col-4">
            <label className="p-mr-1">
              {lang === "EN" ? "Date Range" : "Период"}
            </label>
            <Calendar
              value={dates}
              onChange={(e: any) => setDates(e.value)}
              dateFormat="dd/mm/yy"
              selectionMode="range"
              readOnlyInput
            />
          </div>

          <div className="col-3 pt-4">
            <Button
              type="button"
              icon="pi pi-filter-icon "
              label={lang === "EN" ? "Filter" : "Филтър"}
              // outlined
              disabled={!dates}
              onClick={dateFilterFunction}
              className="mt-3 mt-md-0 w-100"
            />
          </div>
          <div className="col-3 pt-4">
            <Button
              type="button"
              label={lang === "EN" ? "Clear" : "Изчистете"}
              outlined
              onClick={clearDecision}
              className="mt-3 mt-md-0 ms-md-2 w-100"
            />
          </div>
        </div>
      );
    }
  };
  const renderHeader = () => {
    return (
      <div className="d-inline-block justify-content-between headerButtons w-100">
        <div className="d-inline-block mt-4 mt-md-0 ">
          {" "}
        
          <Button
            type="button"
            icon="pi pi-check"
            label={lang === "EN" ? "Approve" : "Одобрение"}
            // outlined
            disabled={!selectedProducts}
            onClick={BulkAcceptRecords}
            className="mt-3 mt-md-0 ms-md-4"
          />
          <Button
            type="button"
            icon="pi pi pi-times"
            label={lang === "EN" ? "Decline" : "Отказване"}
            // outlined
            disabled={!selectedProducts}
            onClick={handleRejectBulk}
            className="mt-3 mt-md-0 ms-md-4"
          />
        </div>
        <div className="d-inline-block float-start float-md-end">
          <span className="p-input-icon-right mt-4 mt-md-0 d-inline-block">
            <i className="pi pi-search" />
            <InputText
              type="search"
              // value={value || ""}
              value={globalFilterValue}
              onChange={(e) => onGlobalFilterChange(e)}
              placeholder={lang === "EN" ? "Global Search" : "Глобално търсене"}
            />
          </span>
        </div>
      </div>
    );
  };
  const header = renderHeader();

  const downloadTemplate = (rowData: any) => {
  

    return (
      <>
        {
          <div>
                        {(rowData.Attachment !== "" || rowData.Attachment !== null) && <a download={rowData.AttachmentName}  href={rowData.Attachment} className="file-link" style={{color:"black"}} target="_blank" rel="noreferrer"> {rowData.AttachmentName} </a> } <br/>                     
                        {(rowData.Attachment1 !== "" || rowData.Attachment1 !== null) &&  <a download={rowData.AttachmentName1} href={rowData.Attachment1} className="file-link" target="_blank" rel="noreferrer"> {rowData.AttachmentName1} </a>}
          </div>
        }
      </>
    );
  };



  const statusBodyTemplate = (rowData: any) => {
    const rowStats = rowData.Status;
    const approvedByVal = rowData.Approvedby;
   if (rowStats === "Accepted" && approvedByVal === "HC Admin") {
      return (
        <Button
          className="p-button-outlined m-1"
          label={lang === "EN" ? "Cancel" : "анулиране"}
          onClick={() =>
            handleCancel(rowData.Id, rowData.StartDate, rowData.EndDate)
          }
        />
      );
    } else {
      return (
        <Tag
          value={rowData.Status}
          style={{ fontSize: "11px" }}
          severity={getSeverity(rowData.Status)}
        />
      );
    }
  };

  return (
    <>
      <div
        className="card"
        id="middleLayout"
        style={{ margin: "20px 20px", padding: "0 5px", borderRadius: "5px" }}
      >
        <Dialog
          header="Alert"
          visible={displayBasic}
          style={{ width: "30vw" }}
          onHide={() => onHide("displayBasic")}
        >
          <div
            className="row"
            id="declinePop"
            style={{ maxHeight: "600px", overflow: "auto" }}
          >
            <div className="col-12">
              <div className="card-body">
                <p className="card-text">
                  {lang === "EN"
                    ? "Are you sure, you want to decline the leave request?"
                    : "Сигурни ли сте, че искате да откажете молбата за отпуск?"}
                </p>
                <InputTextarea
                  placeholder="Please provide your decline reason...."
                  value={remarks}
                  onPaste={(e) => checkPasteChar(e)}
                  onKeyDown={(e) => checkSplChar(e)}
                  onChange={(e) => setRemarks(e.target.value)}
                  rows={3}
                  cols={50}
                />
              </div>
              <Button
                label={lang === "EN" ? "Yes" : "Да"}
                aria-label="Yes"
                className="m-2"
                onClick={() =>
                  updateapproval()
                }
              />
              <Button
                label={lang === "EN" ? "No" : "Не"}
                aria-label="No"
                className="m-2"
                onClick={() => onHide("displayBasic")}
              />
            </div>
          </div>
        </Dialog>
        <Panel
          header={
            lang === "EN" ? "Requests pending my approval" : "Молби към мен"
          }
        ></Panel>
        <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
        <DataTable
          value={data}
          showGridlines
          tableStyle={{ minWidth: "50rem" }}
          header={header}
          globalFilterFields={[
            "EmployeeNo",
            "EmployeeName",
            "StartDate",
            "EndDate",
            "DaysAvailable",
            "LeaveType",
            "Attachment",
            "Comment",
           // "Designation",
            "Approvedby",
          ]}
          filters={filters}
          rows={10}
          paginator
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={[10, 25, 50,100]}
          emptyMessage="No employees found."
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          style={{ fontSize: "0.75rem" }}
          selection={selectedProducts}
          onSelectionChange={(e) => setSelectedProducts(e.value)}
          filterDisplay="row"
          isDataSelectable={isRowSelectable}
          rowClassName={rowClassName}
        >
          <Column
            selectionMode="multiple"
            headerStyle={{ width: "1rem" }}
          ></Column>
          <Column
            field="EmployeeNo"
            filter
            header={lang === "EN" ? "Employee Number" : "Служебен номер"}
            style={{ minWidth: "1rem", maxWidth: "10rem" }}
            className="pe-1"
          ></Column>
          <Column
            field="EmployeeName"
            filter
            header={lang === "EN" ? "Employee Name" : "Име на служител"}
            style={{ minWidth: "1rem", maxWidth: "10rem" }}
            className="pe-1"
          ></Column>
          <Column
            field="StartDate"
            header={lang === "EN" ? "Start Date" : "Начална дата на отпуск"}
            style={{ minWidth: "1rem", maxWidth: "10rem" }}
            className="pe-1"
          ></Column>
          <Column
            field="EndDate"
            header={lang === "EN" ? "End Date" : "Крайна дата на отпуск"}
            style={{ minWidth: "1rem", maxWidth: "10rem" }}
            className="pe-1"
          ></Column>
          <Column
            field="DaysCount"
            header={
              lang === "EN" ? "No of Day/s Requested" : "Брой на заявените дни"
            }
            style={{ minWidth: "1rem", maxWidth: "10rem" }}
          ></Column>
          <Column
            field="DaysAvailable"
            header={
              lang === "EN"
                ? "No of Days Available"
                : "Брой налични дни или налични кредити"
            }
            style={{ minWidth: "1rem", maxWidth: "10rem" }}
          ></Column>
          <Column
            field="LeaveType"
            filter
            header={lang === "EN" ? "Leave type" : "Тип отпуск"}
            style={{ minWidth: "1rem", maxWidth: "10rem" }}
            className="pe-1"
          ></Column>
          <Column
            field="Attachment"
            // sortable

            header={lang === "EN" ? "Attachment" : "Приложени документи"}
            body={downloadTemplate}
          ></Column>
          <Column
            field="Comment"
            filter
            
            header={lang === "EN" ? "Comment" : "Коментари"}
            style={{ minWidth: "1rem", maxWidth: "10rem" }}
            className="pe-1"
          ></Column>
     
          <Column
            field="Approvedby"
            // sortable
            filter
            header={ lang === "EN" ? "Actioned By" : "Отразена от"}
            style={{ minWidth: "1rem", maxWidth: "10rem" }}
            className="pe-1"
          ></Column>
            <Column
              field="NextApprover"
             // sortable
             filter
              header={ lang === "EN" ? "Pending Action" : "В очакване на действие от"}   style={{ minWidth: "1rem", maxWidth: "10rem" }}
              className="pe-1"
            ></Column>
          <Column
            field="DeclineReason"
            filter
            // sortable
            header={lang === "EN" ? "Decline Reason" : "Причина за отказ"}
            style={{ minWidth: "1rem", maxWidth: "10rem" }}
            className="pe-1"
          ></Column>
          <Column
            field="Decision"
            // filter
            body={statusBodyTemplate}
            header={lang === "EN" ? "Decision" : "Решение"}
            style={{ minWidth: "1rem", maxWidth: "10rem" }}
            className="pe-1"
          ></Column>
        </DataTable>
        <Toast ref={toast} />
      </div>
    </>
  );
};

export default LeaveApprovalOrRejectPage;
