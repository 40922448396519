import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { MIDDLE_WARE_BASE_URL, CREATE_LEAVE_REQUEST } from '../../constants';
import axios from '../../datasource/axios-instance'; 

const initialState = {
    createLeaveRequestStatus : null,
    createLeaveRequestState : null,
};

export const  createLeaveRequest = createAsyncThunk(
    'post/createLeaveRequest', async(data : any) =>  {
       const response = await axios.postRequest(MIDDLE_WARE_BASE_URL() + CREATE_LEAVE_REQUEST(), data);
       return response;
});

export const createLeaveRequestSlice = createSlice({
    name : 'createLeaveRequest',
    initialState,
    reducers : {},
    extraReducers : (builder : any) => 
       builder
       .addCase(createLeaveRequest.pending, (state : any) =>
       {
            state.createLeaveRequestState = 'pending';
       })
       .addCase(createLeaveRequest.fulfilled, (state : any,action : any) =>
       {
           state.createLeaveRequestState = 'fulfilled';
           state.createLeaveRequestStatus = 'success';
        })
       .addCase(createLeaveRequest.rejected, (state : any) =>
       {
            state.createLeaveRequestState = 'failed';
            state.createLeaveRequestStatus = 'fail';
       })
});

export const selectCreateLeaveRequest = ((state : any) => state.createLeaveRequest || {});
 
export default createLeaveRequestSlice.reducer;