import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { useState, useEffect, useRef } from "react";
import moment from "moment";
import "./LeaveRequest.scss";
import {
  leaveReqTypes,
  selectleaveReqTypeDetails,
} from "./LeaveRequestTypeSlice";
import {
  leaveHolidayTypes,
  selectleaveHolidayDetails,
} from "./LeaveHolidaysCheckSlice";
import { getEmpDetails, getEmpDetailsDetails } from "./GetEmployeeDetailsSlice";
import {
  createLeaveRequest,
  selectCreateLeaveRequest,
} from "./CreateLeaveRequestSlice";
import { useDispatch, useSelector } from "react-redux";
import { Toast } from "primereact/toast";

//import { LOAD } from "../../constants";
//import { useNavigate } from 'react-router-dom';

export function LeaveRequest() {
  //  const isAuth = localStorage.getItem("AccessToken");
  // const navigate = useNavigate();
  //const gcaLevel = localStorage.getItem("gca");
  const dispatch = useDispatch();
  const toast = useRef<any>(null);
  const [startDate, setStartDate] = useState("");
  const [startDateError, setStartDateError] = useState("");
  const [endDate, setEndDate] = useState("");
  const [endDateError, setEndDateError] = useState("");
  const [days, setDays] = useState("");
  const [daysError, setDaysError] = useState("");
  const [selectedLeaveType, setSelectedLeaveType] = useState("");
  const [leaveTypeError, setErroLeaveTypeError] = useState("");
  const [uploadError, setuploadError] = useState("");
  const [comment, setComment] = useState("");
  const [showUpload, setShowUpload] = useState(false);
  const { leaveReqTypeData } = useSelector(selectleaveReqTypeDetails);
  const { leaveHolidayTypeData, leaveHolidayTypeState } = useSelector(
    selectleaveHolidayDetails
  );
  const { createLeaveRequestState } = useSelector(selectCreateLeaveRequest);
  const { empDetailsData, empDetailsState } = useSelector(getEmpDetailsDetails);
  const [empDetails, setEmpDetails] = useState<any[]>([]);
  const [uploadFirstData, setUploadFirstData] = useState<any>("");
  const [uploadSecondData, setUploadSecondData] = useState<any>("");
  const [uploadFirstFileName, setUploadFirstFileName] = useState<any>("");
  const [uploadSecondFileName, setUploadSecondFileName] = useState<any>("");
  const [showAlert, setShowAlert] = useState(true);
  const [lang, setLang] = useState("EN");
  const fileRef = useRef<HTMLInputElement>(null);
 


  /*  useEffect(() => {
        if (isAuth && (gcaLevel === "D")) {
            navigate(LOAD);
        }
    }, [isAuth, gcaLevel, navigate]); */

  useEffect(() => {
    if (localStorage.getItem("language") === "BG") {
      setLang("BG");
    } else {
      setLang("EN");
    }
  }, []);

  function validate() {
    var status = true;
    let stDate = moment(startDate);
    let edDate = moment(endDate);
    let daysAvailable = moment.duration(edDate.diff(stDate)).asDays() + 1;

    let i = 1;
    if (stDate < edDate) {
      while (i <= daysAvailable) {
        stDate = i === 1 ? stDate : stDate.add(1, "days");

        if (stDate.isoWeekday() === 6 || stDate.isoWeekday() === 7) {
          daysAvailable -= 1;
        }
        i++;
      }
    }
    console.log("daysAvailable", daysAvailable);
    const leave = JSON.parse(JSON.stringify(selectedLeaveType)).code;

    if (startDate === "") {
      setStartDateError(
        lang === "EN"
          ? "Please select Start Date"
          : "Моля, изберете начална дата"
      );
    } else if (daysAvailable <= 0) {
      setStartDateError(
        lang === "EN"
          ? "Start Date should be less than End Date"
          : "Началната дата трябва да е по-малка от крайната"
      );
    } else {
      setStartDateError("");
    }
    if (endDate === "") {
      setEndDateError(
        lang === "EN" ? "Please select End Date" : "Моля, изберете крайна дата"
      );
    } else {
      setEndDateError("");
    }
    if (days === "") {
      setDaysError(
        lang === "EN" ? "Please enter No. of Days" : "Моля, въведете брой дни"
      );
    } else if (empDetails !== null && empDetails[0]?.DaysAvailable < days && (leave === "" || leave === 5)) {
      setDaysError(
        lang === "EN"
          ? "Days should be less than or equal to " +
              empDetails[0]?.DaysAvailable
          : "Дните трябва да са по-малки или равни на" +
              empDetails[0]?.DaysAvailable
      );
    } else if (
      (leave === 1 || leave === 3 || leave === 6) &&
      parseInt(days) > 2
    ) {
      setDaysError(
        lang === "EN"
          ? "Only two days are applicable for the selected leave type"
          : "Само два дни са приложими за избрания тип отпуск"
      );
    } else {
      setDaysError("");
      //status = !status ? true : false;
    }
    if (selectedLeaveType === "") {
      setErroLeaveTypeError(
        lang === "EN" ? "Please select Leave Type" : "Моля, изберете Тип отпуск"
      );
    } else {
      setErroLeaveTypeError("");
    }
    if (leave !== undefined && (leave === 1 || leave === 3 || leave === 6)) {
      if (
        uploadFirstData === "" ||
        uploadFirstData === null ||
        uploadFirstData === undefined
      ) {
        setuploadError(
          lang === "EN" ? "Please upload a file" : "Моля, качете файл"
        );
      } else {
        setuploadError("");
      }
    } else {
      setuploadError("");
    }

    if (
      startDate === "" ||
      endDate === "" ||
      days === "" ||
      selectedLeaveType === "" ||
      (empDetails !== null && empDetails[0]?.DaysAvailable < days && (leave === "" || leave === 5)) ||
      (leave !== undefined &&
        (leave === 1 || leave === 3 || leave === 6) &&
        (uploadFirstData === "" ||
          uploadFirstData === null ||
          uploadFirstData === undefined)) ||
      ((leave === 1 || leave === 3 || leave === 6) && parseInt(days) > 2)
    ) {
      status = false;
    }
    return status;
  }

  function handleSubmit() {
    const leaveApprovalPending: number =
      empDetails !== null ? empDetails[0]?.LeaveApprovalsPending : 0;
    const deathLeavesApprovalPending: number =
      empDetails !== null ? empDetails[0]?.DeathLeaves : 0;
    const weddingLeavesApprovalPending: number =
      empDetails !== null ? empDetails[0]?.WeddingLeaves : 0;
    const bloodDonationLeavesApprovalPending: number =
      empDetails !== null ? empDetails[0]?.BloodDonationLeaves : 0;
    const daysAvailable: number =
      empDetails !== null ? empDetails[0]?.DaysAvailable : 0;
    const leaveId = JSON.parse(JSON.stringify(selectedLeaveType)).code;

    if (leaveId === 1) {
      if (
        deathLeavesApprovalPending !== 0 &&
        deathLeavesApprovalPending >= 2 &&
        parseInt(days) <= deathLeavesApprovalPending
      ) {
        toast.current.show({
          severity: "error",
          summary: "Error Message",
          detail: "Bereavement/ Death Leaves are already pending for approval",
          life: 10000,
        });
        return false;
      }
    } else if (leaveId === 3) {
      if (
        weddingLeavesApprovalPending !== 0 &&
        weddingLeavesApprovalPending >= 2 &&
        parseInt(days) <= weddingLeavesApprovalPending
      ) {
        toast.current.show({
          severity: "error",
          summary: "Error Message",
          detail: "Wedding Leaves are already pending for approval",
          life: 10000,
        });
        return false;
      }
    } else if (leaveId === 6) {
      if (
        bloodDonationLeavesApprovalPending !== 0 &&
        bloodDonationLeavesApprovalPending >= 2 &&
        parseInt(days) <= bloodDonationLeavesApprovalPending
      ) {
        toast.current.show({
          severity: "error",
          summary: "Error Message",
          detail: "Blood Donation Leaves are already pending for approval",
          life: 10000,
        });
        return false;
      }
    }

    if (validate()) {
      if (
        (leaveApprovalPending < daysAvailable &&
        parseInt(days) <= daysAvailable - leaveApprovalPending) || (leaveId === 4)
      ) {
        const data = {
          startDate: moment(startDate).format("MM/DD/yy"),
          endDate: moment(endDate).format("MM/DD/yy"),
          noofDays: days,
          leaveType: JSON.parse(
            JSON.stringify(selectedLeaveType)
          ).code.toString(),
          comments: comment,
          attachment: uploadFirstData,
          attachmentName: uploadFirstFileName,
          attachment1: uploadSecondData,
          attachmentName1: uploadSecondFileName
        };
        //console.log(data);
        dispatch<any>(createLeaveRequest(data));
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error Message",
          detail:
            "No. of leaves requested exceeding the No. of leaves available.You have leaves pending for approval.",
          life: 10000,
        });
      }
    }
  }

  useEffect(() => {
    if (startDate !== "" && endDate !== "") {
      //debugger;
      let stDate = moment(startDate);
      let edDate = moment(endDate);
      let checkDate = moment(startDate);
      let daysdiff = moment.duration(edDate.diff(stDate)).asDays() + 1;

      if (daysdiff > 0 && stDate <= edDate) {
        let reportDate = checkDate.format("MM-DD-YYYY");
        let reportEndDate = edDate.format("MM-DD-YYYY");
        var data = {
          stdate: reportDate,
          edate: reportEndDate,
          oracleIdVal: localStorage.getItem("oracleId"),
        };
        dispatch<any>(leaveHolidayTypes(data));
      }
      if (daysdiff <= 0) {
        setStartDateError("Start Date should be less than End Date");
      } else {
        setStartDateError("");
        setDays("");
      }
    }
  }, [startDate, endDate, dispatch]);

  useEffect(() => {
    if (
      leaveHolidayTypeState === "fulfilled" &&
      startDate !== "" &&
      endDate !== "" &&
      leaveHolidayTypeData !== "" &&
      parseInt(leaveHolidayTypeData) > 0
    ) {
      setDays(leaveHolidayTypeData);
    }
    if (
      parseInt(leaveHolidayTypeData) <= 0 &&
      parseInt(leaveHolidayTypeData) !== -1
    ) {
      setStartDateError("Start Date should be less than End Date");
    } else if (parseInt(leaveHolidayTypeData) === -1) {
      setStartDateError(
        lang === "EN"
          ? "Duplicate leave request exists between the dates selected"
          : "Вече има заявена отпуска за избрания период"
      );
    } else {
      setStartDateError(" ");
      // setDays(" ");
    }
    if (leaveHolidayTypeState !== "fulfilled") {
      setDays("");
    }
    //setDays(days);
  }, [
    leaveHolidayTypeState,
    leaveHolidayTypeData,
    days,
    startDate,
    endDate,
    lang,
  ]);

  useEffect(() => {
    const leave = JSON.parse(JSON.stringify(selectedLeaveType)).code;
    if (leave === undefined || leave === 2 || leave === 4 || leave === 5) {
      setShowUpload(false);
    } else {
      setShowUpload(true);
    }
  }, [selectedLeaveType]);

  useEffect(() => {
    dispatch<any>(leaveReqTypes());
    dispatch<any>(getEmpDetails());
    console.log(process.env.NODE_ENV);
  }, [dispatch]);

  useEffect(() => {
    if (empDetailsState === "fulfilled") {
      setEmpDetails(empDetailsData);
    }
  }, [empDetailsState, empDetailsData]);
 
  useEffect(() => {
    if (createLeaveRequestState === "fulfilled" && showAlert) {
    
       toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Leave Request Submitted Sucessfully",
        life: 10000,
      }); 
      setShowAlert(false);
      handleReset();
       dispatch<any>(getEmpDetails());
    }
    if (createLeaveRequestState === "failed" && showAlert) {
      toast.current.show({
        severity: "error",
        summary: "Error Message",
        detail: "Error Submitting Leave Request",
        life: 10000,
      });
      setShowAlert(false);
    }
  }, [createLeaveRequestState, showAlert,dispatch]);

  const checkSplChar = (e: any) => {
    let key = e.key;
    const arr = ["'", "~", "`", '"'];
    // Check if key pressed is a special character
    if (arr.includes(key)) {
      // Restrict the special characters
      e.preventDefault();
      return false;
    }
  };
  const checkPasteChar = (e: any) => {
    e.preventDefault();
  };
  function handleReset() {
    setStartDate("");
    setEndDate("");
    setDays("");
    setComment("");
    setSelectedLeaveType("");
    setStartDateError("");
    setEndDateError("");
    setDaysError("");
    setErroLeaveTypeError("");
    setShowAlert(true);
    //window.location.reload();
  }

  function onChange(e: any) {
    let file = e.target.files;
    if (file.length > 2)
    {
      toast.current.show({
        severity: "error",
        summary: "Error Message",
        detail: "Only two files are allowed to upload.",
        life: 4000,
      });
      e.target.value = null;
      return;
    }
    else{
    for(let i = 0; i< file.length; i++)
    {
      if (file[i]) {
        let reader = new FileReader();
        reader.readAsDataURL(file[i]);
        if(i ===0)
        setUploadFirstFileName(file[i].name);
        else if(i ===1)
        setUploadSecondFileName(file[i].name);
         
      reader.onload = (e: any) => {
        if(i ===0)
        setUploadFirstData(reader.result);
        else if(i ===1)
        setUploadSecondData(reader.result);
      };
      } else {
        if(i ===0)
        setUploadFirstData("");
        else if(i ===1)
        setUploadSecondData("");
      }
    }
  }
  }

  function onLeaveChange(value: any) {
    setSelectedLeaveType(value);
    setUploadFirstData("");
    setUploadSecondData("");    
    if (fileRef.current !== null) {
      fileRef.current.value = "";
    }
  }

  return (
    <div id="middleLayout">
      <div className="divContainer mb-3 text-black">
    
        <div className="container-fluid fs-4 fw-bold mb-2">
          {" "}
          {lang === "EN" ? "Employee Details" : "информация за служителя"}
        </div>
        <div className="row ps-3 mb-3">
          <div className="col col-6 col-md-3 mb-1 fw-bold">
            {lang === "EN" ? "Employee Name" : "Име на служител"}
          </div>
          <div className="col col-6 col-md-3">
            {empDetails[0]?.EmpName !== null ? empDetails[0]?.EmpName : "NA"}
          </div>
          <div className="col col-6 col-md-3 mb-1 fw-bold">
            {lang === "EN" ? "Employee Number" : "Служебен номер"}
          </div>
          <div className="col col-6 col-md-3">
            {empDetails[0]?.EmpNo !== null ? empDetails[0]?.EmpNo : "NA"}
          </div>
        </div>
        <div className="row ps-3 mb-3">
          <div className="col col-6 col-md-3 mb-1 fw-bold">
            {lang === "EN"
              ? "Manager’s / Supervisor’s Name"
              : "Име на мениджър/супервайзър"}
          </div>
          <div className="col col-6 col-md-3 mb-2">
            {empDetails[0]?.ManagerName !== null
              ? empDetails[0]?.ManagerName
              : "NA"}
          </div>
          <div className="col col-6 col-md-3 mb-1 fw-bold">
            {lang === "EN"
              ? "Manager’s / Supervisor’s Employee Number"
              : "Служебен номер на мениджър/супервайзър"}
          </div>
          <div className="col col-6 col-md-3 mb-2">
            {empDetails[0]?.ManagerNo !== null
              ? empDetails[0]?.ManagerNo
              : "NA"}
          </div>
        </div>
    
        <div className="row ps-3 mb-3">
          <div className="col col-6 col-md-3 mb-1 fw-bold">
            {lang === "EN" ? "No. of Days Available" : "Брой налични дни"}
          </div>
          <div className="col-6 col-lg-3">
            {empDetails[0]?.DaysAvailable !== null
              ? empDetails[0]?.DaysAvailable
              : "NA"}
          </div>
        </div>
      </div>
      <div className="divContainer text-black">
        <div className="container-fluid fs-4 fw-bold mb-4">
          {lang === "EN"
            ? "Request for Leave"
            : "Преглед на заявлението за отпуск"}
        </div>
        <div className="row ps-3">
          <div className="col col-12 col-md-6 col-lg-3 mb-2 fs-6 fw-bold">
            {lang === "EN" ? "Start Date" : "Начална дата на отпуск"}{" "}
            <span className="spn">*</span>
          </div>
          <div className="col col-12 col-md-6 col-lg-3 mb-2">
            <Calendar
              value={startDate}
              disabledDays={[0, 6]}
              onChange={(e: any) => setStartDate(e.value)}
              dateFormat="dd/mm/yy"
              readOnlyInput
            />
            {startDateError !== "" && (
              <p className="frmErrors mt-2"> {startDateError}</p>
            )}
          </div>
          <div className="col col-12 col-md-6 col-lg-3 mb-2 fw-bold">
            {lang === "EN" ? "End Date" : "Крайна дата на отпуск"}{" "}
            <span className="spn">*</span>
          </div>
          <div className="col col-12 col-md-6 col-lg-3 mb-2">
            <Calendar
              value={endDate}
              disabledDays={[0, 6]}
              onChange={(e: any) => setEndDate(e.value)}
              dateFormat="dd/mm/yy"
              readOnlyInput
            />
            {endDateError !== "" && (
              <p className="frmErrors mt-2"> {endDateError}</p>
            )}
          </div>
        </div>
        <div className="row ps-3">
          <div className="col col-12 col-md-6 col-lg-3 mb-2 fw-bold">
            {lang === "EN" ? "Number of days" : "Брой дни"}{" "}
            <span className="spn">*</span>
          </div>
          <div className="col col-12 col-md-6 col-lg-3 mb-2">
            <InputText
              value={days}
              disabled
              onChange={(e: any) => setDays(e.target.value)}
            />
            {daysError !== "" && <p className="frmErrors mt-2"> {daysError}</p>}
          </div>
          <div className="col col-12 col-md-6 col-lg-3 mb-2 fw-bold">
            {lang === "EN" ? "Leave Type" : "Тип отпуск"}
            <span className="spn">*</span>
          </div>
          <div className="col col-12 col-md-6 col-lg-3 mb-2">
            <Dropdown
              value={selectedLeaveType}
              onChange={(e) => onLeaveChange(e.value)}
              options={leaveReqTypeData}
              optionLabel="name"
              placeholder={
                lang === "EN"
                  ? "Please select Leave Type"
                  : "Моля, изберете Тип отпуск"
              }
              className=""
            />
            {leaveTypeError !== "" && (
              <p className="frmErrors mt-2"> {leaveTypeError}</p>
            )}
          </div>
        </div>
        <div className="row ps-3 mt-3">
          <div className="col col-6 col-md-6 col-lg-3 mb-2 fw-bold">
            {lang === "EN" ? "Comment" : "Коментари"}
          </div>
          <div className="col col-12 col-md-6 col-lg-3 mb-2">
            <InputTextarea
              value={comment}
              onPaste={(e) => checkPasteChar(e)}
              onKeyDown={(e) => checkSplChar(e)}
              onChange={(e: any) => setComment(e.target.value)}
              rows={2}
              cols={30}
            />
          </div>
          {showUpload && (
            <div className="col col-12 col-md-6 col-lg-3 mb-2 fw-bold">
              {lang === "EN"
                ? "Attach Required Document"
                : "Приложени документи"}
              <span className="spn">*</span>
            </div>
          )}
          {showUpload && (
            <div className="col col-12 col-md-6 col-lg-3 mb-2">
              <input
                type="file"
                name="file"
               // accept="application/pdf"
                multiple
                className="fileUpload"
                ref={fileRef}
                onChange={(e) => onChange(e)}
              />
              {uploadError !== "" && (
                <p className="frmErrors mt-2"> {uploadError}</p>
              )}
            </div>
          )}
        </div>
        <div className="d-flex flex-row-reverse ps-3 mb-3">
          <div className="p-1">
            <Button
              label={lang === "EN" ? "Submit" : "Бутон за изпращане"}
              severity="secondary"
              raised
              onClick={handleSubmit}
              className="px-4"
            />
          </div>
          <div className="p-1">
            <Button
              label={lang === "EN" ? "Reset" : "Бутон за анулиране"}
              severity="secondary"
              raised
              onClick={handleReset}
              className="px-4"
            />
          </div>
        </div>
      </div>
      <Toast ref={toast} />
    </div>
  );
}