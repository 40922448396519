import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  MIDDLE_WARE_BASE_URL,
  GET_INTIAL_ADMIN_DETAILS,
} from "../../constants";
import axios from "../../datasource/axios-instance";

const initialState = {
    getIntialAdminDetailsStatus: null,
    getIntialAdminDetailsState: null,
    getIntialAdminDetailsData: null,
};

export const IntialAdminDetails = createAsyncThunk(
  "get/getIntialAdminDetails",
  async (data: any) => {
    const response = await axios.getRequest(
      MIDDLE_WARE_BASE_URL() +
      GET_INTIAL_ADMIN_DETAILS() +
        "?oracleId=" +data.oracleId 
    );
    return response;
  }
);

export const getIntialAdminDetailsSlice = createSlice({
  name: "getIntialAdminDetails",
  initialState,
  reducers: {},
  extraReducers: (builder: any) =>
    builder
      .addCase(IntialAdminDetails.pending, (state: any) => {
        state.getIntialAdminDetailsState = "pending";
 
      })
      .addCase(IntialAdminDetails.fulfilled, (state: any, action: any) => {
        state.getIntialAdminDetailsState = "fulfilled";
        state.getIntialAdminDetailsStatus = "success";
        state.getIntialAdminDetailsData = action.payload.data[0].designation;
      })
      .addCase(IntialAdminDetails.rejected, (state: any) => {
        state.getIntialAdminDetailsState = "failed";
        state.getIntialAdminDetailsStatus = "fail";
      }),
});

export const selectIntialAdminDetails = (state: any) =>
  state.getIntialAdminDetails || {};

export default getIntialAdminDetailsSlice.reducer;
